import React                from "react";
import Styled               from "styled-components";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



// Styles
const Title = Styled(DialogMessage)`
    margin: 0;
    color: var(--title-color);
    font-weight: bold;
`;



/**
 * The Contact Merge Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactMerge(props) {
    const { open, clientID, contactIDs, onClose, onSubmit } = props;

    const { merges, fields, defaults } = Store.useState("contactMerge");
    const { fetchMergeData, mergeContacts } = Store.useAction("contactMerge");


    // The Initial Data
    const initialData = {
        clientID   : 0,
        contactIDs : "",
        firstName  : 0,
        lastName   : 0,
        instagram  : 0,
        telegram   : 0,
        address    : 0,
        tongueID   : 0,
        origin     : 0,
        externalID : 0,
    };
    for (const field of fields) {
        initialData[field.customFieldID] = 0;
    }


    // Returns the Contact value
    const getDefaultValue = (contact, field) => {
        switch (field) {
        case "tongueID":
            return contact.languageName;
        case "origin":
            return contact.originName;
        default:
            return contact[field];
        }
    };

    // Handles the Set
    const handleSet = () => {
        const values = {
            clientID,
            contactIDs : JSON.stringify(contactIDs),
        };

        for (const { key } of defaults) {
            for (const contact of merges) {
                if (getDefaultValue(contact, key)) {
                    values[key] = contact.id;
                    break;
                }
            }
            if (!values[key]) {
                values[key] = merges[0].id;
            }
        }

        for (const field of fields) {
            for (const contact of merges) {
                const value = Utils.getValue(contact.fields, "customFieldID", field.customFieldID, "value");
                if (value) {
                    values[field.customFieldID] = contact.id;
                    break;
                }
            }
            if (!values[field.customFieldID]) {
                values[field.customFieldID] = merges[0].id;
            }
        }
        setElem({ ...data, ...values });
    };


    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("contactMerge", initialData, mergeContacts, onSubmit);

    // Load the Data
    const { loading } = useDialog("contactMerge", open, 0, { clientID, contactIDs }, handleSet, fetchMergeData);



    // Do the Render
    return <EditDialog
        open={open}
        icon="merge"
        title="CONTACTS_MERGE_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isWide
    >
        <Title
            variant="h3"
            message="CONTACTS_MERGE_TEXT"
            centered
        />
        <Columns amount={merges.length}>
            <>
                {merges.map((elem) => <Title
                    key={elem.id}
                    variant="h4"
                    message={elem.fullName}
                    centered
                />)}

                {defaults.map(({ key, value }) => <React.Fragment key={key}>
                    {merges.map((elem) => <ViewField
                        key={elem.id}
                        label={value}
                        value={getDefaultValue(elem, key)}
                        onClick={() => handleChange(key, elem.id)}
                        isSelected={elem.id === data[key]}
                        showEmpty
                    />)}
                </React.Fragment>)}

                {fields.map((field) => <React.Fragment key={field.customFieldID}>
                    {merges.map((elem) => <ViewField
                        key={elem.id}
                        label={field.name}
                        value={Utils.getValue(elem.fields, "customFieldID", field.customFieldID, "value")}
                        onClick={() => handleChange(field.customFieldID, elem.id)}
                        isSelected={elem.id === data[field.customFieldID]}
                        showEmpty
                    />)}
                </React.Fragment>)}
            </>
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactMerge.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    clientID   : PropTypes.number,
    contactIDs : PropTypes.array,
};

export default ContactMerge;
