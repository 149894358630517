import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";
import Commons              from "Utils/Commons";
import { Conversation }     from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    modified      : 0,
    elemModified  : 0,
    canCreate     : false,
    canEdit       : false,
    canDelete     : false,
    canAddChannel : false,
    canImport     : false,
    canExport     : false,
    clientID      : 0,
    list          : [],
    total         : 0,
    stats         : {},
    filters       : {},
    platforms     : [],
    showSearch    : false,
    searching     : false,
    controller    : null,
    searchTab     : "contacts",
    search        : {
        contactsList       : [],
        contactsTotal      : 0,
        conversationsList  : [],
        conversationsTotal : 0,
    },
    providers     : [],
    users         : [],
    teams         : [],
    myTeams       : [],
    tags          : [],
    tongues       : [],
    hotels        : [],
    flows         : [],
    assistants    : [],
    msgTemplates  : [],
    variables     : [],
    channelLinks  : [],
    channels      : [],
    wabaTemplates : [],
    customFields  : [],
    messageLength : 0,
    smsCost       : 0,
    elem          : {
        id             : 0,
        conversationID : 0,
        fields         : [],
        visibleFields  : [],
    },
    elemText      : "",
    elemContact   : {},
    contact       : {
        id           : 0,
        fields       : [],
        channelItems : [],
    },
    hospitality   : {},
    quotation     : {},
    order         : {},
    conversations : [],
    accounts      : [],
    recentNotes   : [],
    sort          : {
        filter  : 0,
        page    : 0,
        amount  : 20,
        perPage : 10,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CONVERSATION_LOADING" });
    },

    /**
     * Fetches the Conversation List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Conversation.getAll(params);
        data.sort  = params;
        dispatch({ type : "CONVERSATION_LIST", data });
    },

    /**
     * Fetches a single Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchElem(dispatch, conversationID, clientID) {
        const data = await Conversation.getOne({ conversationID, clientID });
        dispatch({ type : "CONVERSATION_ELEM", data });
    },

    /**
     * Saves the Conversations Filters
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async saveFilters(dispatch, params) {
        const sort = {
            ...initialState.sort,
            filter : params.status,
        };
        const data = await Conversation.saveFilters({ ...params, ...sort });

        data.sort = sort;
        dispatch({ type : "CONVERSATION_LIST", data });
        return data;
    },

    /**
     * Shows the Search
     * @param {Function} dispatch
     * @returns {Void}
     */
    startSearch(dispatch) {
        dispatch({ type : "CONVERSATION_START_SEARCH" });
    },

    /**
     * Searches the Contacts and Conversations
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {String}   text
     * @returns {Promise}
     */
    searchList(dispatch, clientID, text) {
        const controller = new window.AbortController();
        dispatch({ type : "CONVERSATION_GET_SEARCH", controller });
        return Conversation.search({ clientID, text }, controller).then((data) => {
            dispatch({ type : "CONVERSATION_SET_SEARCH", data });
            return data;
        });
    },

    /**
     * Sets the Search Tab
     * @param {Function} dispatch
     * @param {String}   tab
     * @returns {Void}
     */
    setSearchTab(dispatch, tab) {
        dispatch({ type : "CONVERSATION_TAB_SEARCH", tab });
    },

    /**
     * Ends the Search
     * @param {Function} dispatch
     * @returns {Void}
     */
    endSearch(dispatch) {
        dispatch({ type : "CONVERSATION_END_SEARCH" });
    },

    /**
     * Fetches the Conversation Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number}   contactID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID, contactID) {
        const data = await Conversation.getEditData({ clientID, contactID });
        dispatch({ type : "CONVERSATION_EDIT", data });
    },

    /**
     * Creates a Conversation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    createConversation(dispatch, params) {
        return Conversation.create(params);
    },

    /**
     * Edits a Conversation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editConversation(dispatch, params) {
        const data = await Conversation.edit(params);
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Set Writing field of the given Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   isWriting
     * @returns {Promise}
     */
    setWriting(dispatch, conversationID, isWriting) {
        return Conversation.setWriting({ conversationID, isWriting });
    },

    /**
     * Set the View of the current User
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   clientID
     * @returns {Promise}
     */
    setLastView(dispatch, conversationID, clientID) {
        return Conversation.setLastView({ conversationID, clientID });
    },

    /**
     * Marks the Conversation as Read
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async markAsRead(dispatch, conversationID, clientID) {
        const data = await Conversation.markAsRead({ conversationID, clientID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Accepts the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @returns {Promise}
     */
    async acceptConversation(dispatch, conversationID) {
        const data = await Conversation.accept({ conversationID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Returns the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @returns {Promise}
     */
    async returnConversation(dispatch, conversationID) {
        const data = await Conversation.return({ conversationID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Followups the Conversation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async followupConversation(dispatch, params) {
        const data = await Conversation.followup(params);
        dispatch({ type : "CONVERSATION_ACTION", data });
    },

    /**
     * Progresses the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @returns {Promise}
     */
    async progressConversation(dispatch, conversationID) {
        const data = await Conversation.progress({ conversationID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Resolves the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @returns {Promise}
     */
    async resolveConversation(dispatch, conversationID) {
        const data = await Conversation.resolve({ conversationID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Opens the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @returns {Promise}
     */
    async openConversation(dispatch, conversationID) {
        const data = await Conversation.open({ conversationID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Assigns an User to the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   userID
     * @returns {Promise}
     */
    async assignUser(dispatch, conversationID, userID) {
        const data = await Conversation.assignUser({ conversationID, userID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Assigns a Team to the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   teamID
     * @returns {Promise}
     */
    async assignTeam(dispatch, conversationID, teamID) {
        const data = await Conversation.assignTeam({ conversationID, teamID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Assigns a Tag to the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   tagID
     * @returns {Promise}
     */
    async assignTag(dispatch, conversationID, tagID) {
        const data = await Conversation.assignTag({ conversationID, tagID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Assigns a Tongue to the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   tongueID
     * @returns {Promise}
     */
    async assignTongue(dispatch, conversationID, tongueID) {
        const data = await Conversation.assignTongue({ conversationID, tongueID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Assigns a Hotel to the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   hotelID
     * @returns {Promise}
     */
    async assignHotel(dispatch, conversationID, hotelID) {
        const data = await Conversation.assignHotel({ conversationID, hotelID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Assigns a Flow to the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @param {Number}   flowID
     * @returns {Promise}
     */
    async assignFlow(dispatch, conversationID, flowID) {
        const data = await Conversation.assignFlow({ conversationID, flowID });
        if (!data.error) {
            dispatch({ type : "CONVERSATION_ACTION", data });
        }
    },

    /**
     * Generates a Reply / Note for the Conversation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async generateConversation(dispatch, params) {
        return Conversation.generate(params);
    },

    /**
     * Summarizes the Conversation
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async summarizeConversation(dispatch, params) {
        return Conversation.summarize(params);
    },

    /**
     * Deletes the Conversation
     * @param {Function} dispatch
     * @param {Number}   conversationID
     * @returns {Promise}
     */
    async deleteConversation(dispatch, conversationID) {
        const result = await Conversation.delete({ conversationID });
        if (result.success) {
            dispatch({ type : "CONVERSATION_DELETED" });
        }
        return result;
    },

    /**
     * Triggers a Conversation Update
     * @param {Function} dispatch
     * @returns {Void}
     */
    triggerElemUpdate(dispatch) {
        dispatch({ type : "CONVERSATION_ELEM_MODIFIED" });
    },
};



/**
 * Parses a list of Elements
 * @param {Object[]} list
 * @returns {Object}
 */
function parseList(list) {
    return Utils.parseList(list, Conversations.parseElem);
}

/**
 * Handle the Element
 * @param {Object} state
 * @param {Object} elem
 * @returns {Object}
 */
function handleElem(state, elem) {
    if (!elem) {
        return {
            modified : false,
            elemText : "",
            elem     : {},
        };
    }

    const copy     = Utils.clone(elem);
    const elemText = JSON.stringify(copy);
    const areEqual = elemText === state.elemText;

    return {
        modified : state.modified + (areEqual ? 0 : 1),
        elemText : elemText,
        elem     : Conversations.parseElem(elem),
    };
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CONVERSATION_LIST", "CONVERSATION_ELEM",
        "CONVERSATION_EDIT", "CONVERSATION_ACTION"
    )) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CONVERSATION_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "CONVERSATION_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canDelete     : action.data.canDelete,
            canAddChannel : action.data.canAddChannel,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            clientID      : action.data.clientID,
            list          : parseList(action.data.list),
            stats         : action.data.stats,
            filters       : action.data.filters,
            platforms     : action.data.platforms,
            providers     : action.data.providers,
            users         : action.data.users,
            teams         : action.data.teams,
            myTeams       : action.data.myTeams,
            tags          : action.data.tags,
            tongues       : action.data.tongues,
            hotels        : action.data.hotels,
            flows         : action.data.flows,
            assistants    : action.data.assistants,
            msgTemplates  : action.data.msgTemplates,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            wabaTemplates : action.data.wabaTemplates,
            messageLength : action.data.messageLength,
            customFields  : action.data.customFields,
            total         : action.data.stats[action.data.sort.filter]?.total ?? 0,
            sort          : action.data.sort,
        };

    case "CONVERSATION_START_SEARCH": {
        if (state.controller) {
            state.controller.abort();
        }
        return {
            ...state,
            showSearch    : true,
            searching     : false,
            searchTab     : "contacts",
            controller    : null,
        };
    }

    case "CONVERSATION_GET_SEARCH": {
        if (state.controller) {
            state.controller.abort();
        }
        return {
            ...state,
            searching     : true,
            controller    : action.controller,
        };
    }

    case "CONVERSATION_SET_SEARCH":
        return {
            ...state,
            searching     : false,
            controller    : null,
            search        : {
                contactsList       : parseList(action.data.contactsList),
                contactsTotal      : action.data.contactsTotal,
                conversationsList  : parseList(action.data.conversationsList),
                conversationsTotal : action.data.conversationsTotal,
            },
        };

    case "CONVERSATION_TAB_SEARCH":
        return {
            ...state,
            searchTab     : action.tab,
        };

    case "CONVERSATION_END_SEARCH": {
        if (state.controller) {
            state.controller.abort();
        }
        return {
            ...state,
            showSearch    : false,
            searching     : false,
            controller    : null,
            search        : {
                contactsList       : [],
                contactsTotal      : 0,
                conversationsList  : [],
                conversationsTotal : 0,
            },
        };
    }

    case "CONVERSATION_EDIT":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            channels      : action.data.channels,
            msgTemplates  : action.data.msgTemplates,
            wabaTemplates : action.data.wabaTemplates,
            variables     : action.data.variables,
            channelLinks  : action.data.channelLinks,
            assistants    : action.data.assistants,
            smsCost       : action.data.smsCost,
            elemContact   : Commons.parseContact(action.data.contact),
        };

    case "CONVERSATION_ELEM": {
        const { modified, elem, elemText } = handleElem(state, action.data.elem);
        return {
            ...state, modified, elem, elemText,
            error         : false,
            edition       : state.edition + 1,
            contact       : Commons.parseContact(action.data.contact),
            hospitality   : Commons.parseHospitality(action.data.hospitality),
            quotation     : Commons.parseQuotation(action.data.quotation),
            order         : Commons.parseOrder(action.data.order),
            conversations : Conversations.parseList(action.data.conversations),
            accounts      : action.data.accounts,
            recentNotes   : action.data.recentNotes,
        };
    }

    case "CONVERSATION_ACTION": {
        const { modified, elem, elemText } = handleElem(state, action.data.elem);
        return {
            ...state, modified, elem, elemText,
            error         : false,
            contact       : Commons.parseContact(action.data.contact),
            hospitality   : Commons.parseHospitality(action.data.hospitality),
            quotation     : Commons.parseQuotation(action.data.quotation),
            order         : Commons.parseOrder(action.data.order),
            conversations : Conversations.parseList(action.data.conversations),
            accounts      : action.data.accounts,
            recentNotes   : action.data.recentNotes,
        };
    }

    case "CONVERSATION_NEW_MESSAGES": {
        const { modified, elem, elemText } = handleElem(state, action.data.elem);
        return {
            ...state, modified, elem, elemText,
        };
    }

    case "CONVERSATION_NOTE_UPDATE":
        return {
            ...state,
            recentNotes   : action.data.recentNotes,
        };

    case "CONVERSATION_DELETED":
        return {
            ...state,
            modified      : state.modified + 1,
        };

    case "CONVERSATION_ELEM_MODIFIED":
        return {
            ...state,
            elemModified  : state.elemModified + 1,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
