import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import Zoom                 from "Components/Utils/Common/Zoom";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.header`
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    height: var(--inters-actions);
    background-color: var(--lightest-gray);
    border-bottom: 1px solid var(--border-color-light);

    @media screen and (max-width: 450px) {
        .btn-content {
            display: none;
        }
    }
`;

const Actions = Styled.section`
    display: flex;
    gap: 8px;
`;



/**
 * The Integration Actions
 * @returns {React.ReactElement}
 */
function IntegrationActions() {
    const { integrationID, actions } = Store.useState("integrationEditor");
    const { hasChanges, zoom, selectedAction } = Store.useState("integrationState");

    const { copyAction, moveAction } = Store.useAction("integrationEditor");
    const { setAction, setZoom } = Store.useAction("integrationState");


    // Handles the Copy
    const handleCopy = () => {
        if (hasChanges) {
            setAction("WARNING");
        } else if (selectedAction) {
            copyAction(selectedAction);
        }
    };

    // Handles the Move
    const handleMove = (direction) => {
        if (hasChanges) {
            setAction("WARNING");
        } else if (selectedAction) {
            moveAction(selectedAction, direction);
        }
    };

    // Handles the Delete
    const handleDelete = () => {
        if (selectedAction) {
            setAction("REMOVE");
        }
    };

    // Handles the Zoom Minus
    const handleZoomMinus = (e) => {
        setZoom(integrationID, zoom - 10);
        e.preventDefault();
    };

    // Handles the Zoom Plus
    const handleZoomPlus = (e) => {
        setZoom(integrationID, zoom + 10);
        e.preventDefault();
    };

    // Handles the Zoom Reset
    const handleZoomReset = (e) => {
        setZoom(integrationID, 100);
        e.preventDefault();
    };



    // Variables
    const action      = selectedAction ? Utils.getValue(actions, "id", selectedAction) : null;
    const canCopy     = Boolean(action && !action.isTrigger && !action.isUnique);
    const canMoveUp   = Boolean(action && !action.isTrigger && action.position > 2);
    const canMoveDown = Boolean(action && !action.isTrigger && action.position < actions.length);
    const canDelete   = Boolean(action);


    // Do the Render
    return <Container>
        <Actions>
            <Button
                isHidden={!canCopy}
                variant="white"
                message="GENERAL_COPY"
                icon="copy"
                onClick={handleCopy}
                isSmall
            />
            <Button
                isHidden={!canMoveUp}
                variant="white"
                message="GENERAL_MOVE"
                icon="up"
                onClick={() => handleMove(-1)}
                isSmall
            />
            <Button
                isHidden={!canMoveDown}
                variant="white"
                message="GENERAL_MOVE"
                icon="down"
                onClick={() => handleMove(1)}
                isSmall
            />
            <Button
                isHidden={!canDelete}
                variant="white"
                message="GENERAL_DELETE"
                icon="delete"
                onClick={handleDelete}
                isSmall
            />
        </Actions>
        <Zoom
            zoom={zoom}
            onMinus={handleZoomMinus}
            onPlus={handleZoomPlus}
            onReset={handleZoomReset}
        />
    </Container>;
}

export default IntegrationActions;
