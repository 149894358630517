import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Conversations        from "Utils/Conversations";

// Components
import ChatFollowup         from "./ChatFollowup";
import ChatSummarize        from "./ChatSummarize";
import ContactBlock         from "Components/App/Client/Contact/Dialogs/ContactBlock";
import AccountAssign        from "Components/App/Client/Account/Dialogs/AccountAssign";
import TagEdit              from "Components/App/Config/Tag/TagEdit";

// Dashboard
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



// Styles
const Container = Styled.main`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
    background-color: var(--lightest-gray);
    border-bottom: 1px solid var(--border-color-light);

    .btn.btn {
        height: auto;
    }
`;

const Actions = Styled(ActionList)`
    flex-wrap: wrap;
    justify-content: flex-start;
`;



/**
 * The Chat Actions
 * @returns {React.ReactElement}
 */
function ChatActions() {
    const navigate = Conversations.useGoto();
    const { conversationTab, conversationID } = Navigate.useParams();

    const { isClientUser } = Store.useState("auth");
    const { hasAccount } = Store.useState("permission");

    const {
        canEdit, canDelete, elem, contact,
        users, teams, tags, tongues, hotels, flows, assistants,
    } = Store.useState("conversation");

    const {
        acceptConversation, returnConversation, progressConversation,
        resolveConversation, openConversation, deleteConversation, triggerElemUpdate,
        assignUser, assignTeam, assignTag, assignTongue, assignHotel, assignFlow,
    } = Store.useAction("conversation");


    // The Current State
    const [ openDialog, setOpenDialog ] = React.useState("");
    const [ loading,    setLoading    ] = React.useState(false);

    // Generates the lists
    const tagList   = [ ...tags, { key : 0, value : "TAGS_CREATE_TITLE" }];
    const teamList  = [ ...teams ];
    const hotelList = [ ...hotels ];

    if (elem.teamID) {
        teamList.unshift({ key : 0, value : "GENERAL_NONE" });
    }
    if (elem.hotelID) {
        hotelList.unshift({ key : 0, value : "GENERAL_NONE" });
    }


    // Handles the Accept Action
    const handleAccept = () => {
        acceptConversation(elem.id);
        navigate("PROGRESS", elem.number);
    };

    // Handles the Return Action
    const handleReturn = () => {
        returnConversation(elem.id);
    };

    // Handles the Progress Action
    const handleProgress = () => {
        if (elem.isFollowup) {
            progressConversation(elem.id);
        }
    };

    // Handles the Resolve Action
    const handleResolve = () => {
        if (elem.isProgress || elem.isFollowup) {
            resolveConversation(elem.id);
        }
    };

    // Handles the Open Action
    const handleOpen = () => {
        if (elem.isResolved) {
            openConversation(elem.id);
        }
    };


    // Handles the User Action
    const handleUser = (userID) => {
        if (userID !== elem.userID) {
            assignUser(elem.id, userID);
        }
    };

    // Handles the Team Action
    const handleTeam = (teamID) => {
        if (teamID !== elem.teamID) {
            assignTeam(elem.id, teamID);
        }
    };

    // Handles the Tag Action
    const handleTag = (tagID) => {
        if (!tagID) {
            setOpenDialog("tag");
        } else {
            assignTag(elem.id, tagID);
        }
    };

    // Handles the Tag Create Action
    const handleTagCreate = (response) => {
        if (response.tagID) {
            assignTag(elem.id, response.tagID);
        }
        setOpenDialog("");
    };

    // Handles the Tongue Action
    const handleTongue = (tongueID) => {
        if (tongueID !== elem.tongueID) {
            assignTongue(elem.id, tongueID);
        }
    };

    // Handles the Hotel Action
    const handleHotel = (hotelID) => {
        if (hotelID !== elem.hotelID) {
            assignHotel(elem.id, hotelID);
        }
    };

    // Handles the Flow Action
    const handleFlow = (flowID) => {
        assignFlow(elem.id, flowID);
    };

    // Handles an Action
    const handleAction = () => {
        triggerElemUpdate();
        setOpenDialog("");
    };

    // Handles the Conversation Delete
    const handleDelete = async () => {
        setLoading(true);
        const result = await deleteConversation(elem.id);
        if (result.success) {
            navigate(conversationTab);
        }
        setOpenDialog("");
        setLoading(false);
    };


    // Variables
    const canAccept        = Boolean(isClientUser && elem.isQueue);
    const canReturn        = Boolean(elem.isProgress || elem.isFollowup);
    const canProgress      = Boolean(elem.isFollowup);
    const canFollowup      = Boolean(elem.isProgress);
    const canResolve       = Boolean(elem.isProgress || elem.isFollowup);
    const canOpen          = Boolean(elem.isResolved && !elem.channelIsDeleted && contact.id && !contact.isBlocked);

    const canAssignUser    = Boolean(!elem.isResolved);
    const canAssignTeam    = Boolean(!elem.isResolved && teams.length);
    const canAssignTag     = Boolean(!elem.isResolved && tags.length);
    const canAssignTongue  = Boolean(!elem.isResolved && tongues.length);
    const canAssignHotel   = Boolean(!elem.isResolved && hotels.length > 1);
    const canAssignFlow    = Boolean(!elem.isResolved && flows.length);

    const canAssignAccount = Boolean(canEdit && hasAccount);
    const canBlock         = Boolean(canEdit && elem.contactID);
    const canSummarize     = Boolean(assistants.length);


    // Do the Render
    if (!conversationID || !elem.id) {
        return <React.Fragment />;
    }
    return <>
        <Container>
            <div>
                <Actions>
                    <ActionItem
                        isHidden={!canAccept}
                        variant="primary"
                        icon="progress"
                        message="GENERAL_ACCEPT"
                        onClick={handleAccept}
                        isSmall
                    />
                    <ActionItem
                        isHidden={!canReturn}
                        variant="primary"
                        icon="queue"
                        message="CONVERSATIONS_RETURN"
                        onClick={handleReturn}
                        isSmall
                    />
                    <ActionItem
                        isHidden={!canFollowup}
                        variant="white"
                        icon="followup"
                        message="CONVERSATIONS_FOLLOWUP"
                        onClick={() => setOpenDialog("followup")}
                        isSmall
                    />
                    <ActionItem
                        isHidden={!canProgress}
                        variant="white"
                        icon="progress"
                        message="CONVERSATIONS_PROGRESS"
                        onClick={handleProgress}
                        isSmall
                    />
                    <ActionItem
                        isHidden={!canResolve}
                        variant="primary"
                        icon="resolved"
                        message="CONVERSATIONS_RESOLVE"
                        onClick={handleResolve}
                        isSmall
                    />
                    <ActionItem
                        isHidden={!canOpen}
                        variant="primary"
                        icon="progress"
                        message="GENERAL_OPEN"
                        onClick={handleOpen}
                        isSmall
                    />
                </Actions>
            </div>

            <ActionList>
                <ActionItem
                    variant="white"
                    icon="more"
                    message="GENERAL_ACTIONS"
                    isSmall
                >
                    <ActionOption
                        isHidden={!canAssignUser}
                        direction="left"
                        icon="user"
                        message="CONVERSATIONS_ASSIGN_USER"
                    >
                        {users.map(({ key, value }) => <MenuItem
                            key={key}
                            value={key}
                            message={value}
                            icon={key === elem.userID ? "checkedbox" : "checkbox"}
                            onClick={() => handleUser(key)}
                        />)}
                    </ActionOption>

                    <ActionOption
                        isHidden={!canAssignTeam}
                        direction="left"
                        icon="team"
                        message="CONVERSATIONS_ASSIGN_TEAM"
                    >
                        {teamList.map(({ key, value }) => <MenuItem
                            key={key}
                            value={key}
                            message={value}
                            icon={!key ? "none" : (key === elem.teamID ? "checkedbox" : "checkbox")}
                            onClick={() => handleTeam(key)}
                        />)}
                    </ActionOption>

                    <ActionOption
                        isHidden={!canAssignTag}
                        direction="left"
                        icon="tag"
                        message="CONVERSATIONS_ASSIGN_TAG"
                    >
                        {tagList.map(({ key, value }) => <MenuItem
                            key={key}
                            value={key}
                            message={value}
                            icon={!key ? "tag" : (Object.keys(elem.tags).includes(String(key)) ? "checkedbox" : "checkbox")}
                            onClick={() => handleTag(key)}
                        />)}
                    </ActionOption>

                    <ActionOption
                        isHidden={!canAssignTongue}
                        direction="left"
                        icon="language"
                        message="CONVERSATIONS_ASSIGN_LANGUAGE"
                    >
                        {tongues.map(({ key, value }) => <MenuItem
                            key={key}
                            value={key}
                            message={value}
                            icon={key === elem.tongueID ? "checkedbox" : "checkbox"}
                            onClick={() => handleTongue(key)}
                        />)}
                    </ActionOption>

                    <ActionOption
                        isHidden={!canAssignHotel}
                        direction="left"
                        icon="hotel"
                        message="CONVERSATIONS_ASSIGN_HOTEL"
                    >
                        {hotelList.map(({ key, value }) => <MenuItem
                            key={key}
                            value={key}
                            message={value}
                            icon={!key ? "none" : (key === elem.hotelID ? "checkedbox" : "checkbox")}
                            onClick={() => handleHotel(key)}
                        />)}
                    </ActionOption>

                    <ActionOption
                        isHidden={!canAssignFlow}
                        direction="left"
                        icon="flow"
                        message="CONVERSATIONS_ASSIGN_FLOW"
                    >
                        {flows.map(({ key, value }) => <MenuItem
                            key={key}
                            value={key}
                            message={value}
                            icon={key === elem.flowID ? "checkedbox" : "checkbox"}
                            onClick={() => handleFlow(key)}
                        />)}
                    </ActionOption>

                    <ActionOption
                        isHidden={!canAssignAccount}
                        icon="account"
                        message="ACCOUNTS_ASSIGN_ACCOUNT_TITLE"
                        onClick={() => setOpenDialog("account")}
                    />
                    <ActionOption
                        isHidden={!canBlock}
                        icon="block"
                        message={contact.isBlocked ? "CONTACTS_UNBLOCK_TITLE" : "CONTACTS_BLOCK_TITLE"}
                        onClick={() => setOpenDialog("block")}
                    />

                    <ActionOption
                        isHidden={!canSummarize}
                        icon="summary"
                        message="CONVERSATIONS_SUMMARIZE_TITLE"
                        onClick={() => setOpenDialog("summarize")}
                    />
                    <ActionOption
                        isHidden={!canDelete}
                        icon="delete"
                        message="CONVERSATIONS_DELETE_TITLE"
                        onClick={() => setOpenDialog("delete")}
                    />
                </ActionItem>
            </ActionList>
        </Container>

        <TagEdit
            open={openDialog === "tag"}
            clientID={elem.clientID}
            onSubmit={handleTagCreate}
            onClose={() => setOpenDialog("")}
        />
        <ChatFollowup
            open={openDialog === "followup"}
            clientID={elem.clientID}
            conversationID={elem.conversationID}
            onClose={() => setOpenDialog("")}
        />
        <ChatSummarize
            open={openDialog === "summarize"}
            clientID={elem.clientID}
            conversationID={elem.conversationID}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <ContactBlock
            open={openDialog === "block"}
            elem={contact}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <AccountAssign
            openAssign={openDialog === "account"}
            elemName={contact.fullName}
            contactID={contact.id}
            clientID={contact.clientID}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <DeleteDialog
            open={openDialog === "delete"}
            isLoading={loading}
            title="CONVERSATIONS_DELETE_TITLE"
            message="CONVERSATIONS_DELETE_TEXT"
            onSubmit={handleDelete}
            onClose={() => setOpenDialog("")}
        />
    </>;
}

export default ChatActions;
