import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import useFilter            from "Dashboard/Hooks/Filter";
import Commons              from "Utils/Commons";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Dashboard Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardFilter(props) {
    const { open, type, clientID, partnerID, filters, onClose } = props;

    const { isAgent, isAnyAdmin } = Store.useState("auth");

    const {
        partners, clients, idsPerPartner, providers, channels, hotels, users,
        initialFilter,
    } = Store.useState("dashboardFilter");

    const { saveFilters } = Store.useAction("dashboardFilter");


    // Handles the Filter Submit
    const onSubmit = (filters) => {
        saveFilters({
            type      : type,
            clientID  : clientID,
            partnerID : partnerID,
            filters   : JSON.stringify({ ...initialFilter, ...filters }),
        });
        onClose();
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("dashboardFilter", open, initialFilter, filters, onSubmit, { clientID, partnerID });

    // Selects
    const getClients = Utils.useSelectList(loading, data.partnerID, clients, idsPerPartner);


    // Do the Render
    const isReport = type === "reports";

    return <FilterDialog
        open={open}
        title="DASHBOARD_FILTER_TITLE"
        initialData={initialFilter}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="period"
            label="PERIOD_NAME"
            options={isReport ? Period.getLastSelect(true) : Period.getSimpleSelect()}
            value={data.period}
            onChange={handleChange}
        />

        <InputField
            isHidden={!partners.length}
            type="chooser"
            name="partnerID"
            label="PARTNERS_NAME"
            options={partners}
            value={data.partnerID}
            onChange={handleChange}
            hasClear
        />
        <InputField
            isHidden={!clients.length}
            type="chooser"
            name="clientID"
            label="CLIENTS_NAME"
            options={getClients()}
            value={data.clientID}
            onChange={handleChange}
            hasClear
        />
        <InputField
            isHidden={!providers.length}
            type="chooser"
            name="providerName"
            label="GENERAL_PLATFORM"
            options={Commons.getPlatformSelect(providers)}
            value={data.providerName}
            onChange={handleChange}
            hasClear
        />

        <InputField
            isHidden={!channels.length}
            type="chooser"
            name="providerName"
            label="GENERAL_PLATFORM"
            options={Commons.getPlatformSelect(channels)}
            value={data.providerName}
            onChange={handleChange}
            hasClear
        />
        <InputField
            isHidden={!channels.length}
            type="chooser"
            name="channelID"
            label="CHANNELS_SINGULAR"
            options={Commons.getChannelSelect(channels)}
            value={data.channelID}
            onChange={handleChange}
            hasClear
        />
        <InputField
            isHidden={!hotels.length}
            type="chooser"
            name="hotelID"
            label="HOTELS_SINGULAR"
            options={hotels}
            value={data.hotelID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
            hasClear
        />
        <InputField
            isHidden={!users.length || isAgent || !!data.onlyMine}
            type="chooser"
            name="userID"
            label="USERS_NAME"
            options={users}
            value={data.userID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
            hasClear
        />
        <InputField
            isHidden={isAgent || isAnyAdmin}
            type="toggle"
            name="onlyMine"
            label="GENERAL_ONLY_MINE"
            value={!!data.onlyMine}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardFilter.propTypes = {
    open      : PropTypes.bool.isRequired,
    type      : PropTypes.string.isRequired,
    onClose   : PropTypes.func.isRequired,
    clientID  : PropTypes.number,
    partnerID : PropTypes.number,
    filters   : PropTypes.object,
};

export default DashboardFilter;
