import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Subscription Wabas View
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionWabas(props) {
    const { isHidden, elem } = props;


    // Do the Render
    if (isHidden || !elem.id || !elem.wabas.length) {
        return <React.Fragment />;
    }
    return <Table noSorting notFixed rightSpace>
        <TableHead>
            <TableHeader field="channelName"            message="CHANNELS_SINGULAR"                     grow="2" isTitle />
            <TableHeader field="marketingConversations" message="SUBSCRIPTIONS_MARKETING_CONVERSATIONS" align="center" />
            <TableHeader field="utilityConversations"   message="SUBSCRIPTIONS_UTILITY_CONVERSATIONS"   align="center" />
            <TableHeader field="referralConversations"  message="SUBSCRIPTIONS_REFERRAL_CONVERSATIONS"  align="center" />
            <TableHeader field="serviceConversations"   message="SUBSCRIPTIONS_SERVICE_CONVERSATIONS"   align="center" />
            <TableHeader field="total"                  message="GENERAL_TOTAL"                         align="center" maxWidth="80" rightSpace />
        </TableHead>
        <TableBody>
            {elem.wabas.map((waba) => <TableRow key={waba.channelID} elemID={waba.channelID}>
                <TableCell message={waba.channelName}            />
                <TableCell message={waba.marketingConversations} />
                <TableCell message={waba.utilityConversations}   />
                <TableCell message={waba.referralConversations}  />
                <TableCell message={waba.serviceText}            />
                <TableCell message={waba.total}                  />
            </TableRow>)}
        </TableBody>
    </Table>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionWabas.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    elem     : PropTypes.object.isRequired,
};

export default SubscriptionWabas;
