import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import WabaTemplateMain     from "../Options/WabaTemplateMain";
import WabaTemplateHead     from "../Options/WabaTemplateHead";
import WabaTemplateBody     from "../Options/WabaTemplateBody";
import WabaTemplateFoot     from "../Options/WabaTemplateFoot";
import WabaTemplateButtons  from "../Options/WabaTemplateButtons";

// Dashboard
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageHeader           from "Dashboard/Components/Page/PageHeader";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageFooter           from "Dashboard/Components/Page/PageFooter";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import Alert                from "Dashboard/Components/Form/Alert";
import Button               from "Dashboard/Components/Form/Button";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The WABA Template Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WabaTemplateEditor(props) {
    const {
        loading, clientID, hasTabs, getValue, getError,
        onChange, setData, onDraft, onPublish, onDelete, onClose,
    } = props;

    const { elem, channels } = Store.useState("wabaTemplate");


    // Variables
    const isEdit           = Boolean(getValue("wabaTemplateID"));
    const isComponentEdit  = Boolean(getValue("componentID"));
    const inProvider       = Boolean(getValue("providerID"));
    const canEdit          = Boolean(getValue("canEdit"));
    const status           = getValue("status");
    const rejectedReason   = getValue("rejectedReason");

    const showReason       = Boolean(status === "Rejected" && rejectedReason);
    const isMainDisabled   = Boolean(isEdit && elem.status !== "Draft");
    const isGlobalDisabled = Boolean(isEdit && isComponentEdit && inProvider && !canEdit);
    const showHours        = Boolean(status === "Approved" && isGlobalDisabled);
    const showCantEdit     = Boolean(status !== "Approved" && isGlobalDisabled);
    const canSaveDraft     = Boolean(!isEdit || !inProvider);
    const shouldPublish    = Boolean(status === "Draft");


    // Do the Render
    return <PageContainer hasTabs={hasTabs}>
        <PageHeader>
            <Html
                isHidden={!showHours}
                message="WABA_TEMPLATES_CANT_HOURS"
            />
            <Html
                isHidden={!showCantEdit}
                message="WABA_TEMPLATES_CANT_EDIT"
            />
            <Html
                isHidden={!showReason}
                className="text-red"
                message={NLS.format("WABA_TEMPLATES_REJECTED", rejectedReason)}
            />
            <Alert variant="error" message={getError("form")} />
        </PageHeader>

        <PageAccordion initial={!isEdit ? "main" : ""}>
            <AccordionItem
                value="main"
                message="GENERAL_MAIN"
                description="WABA_TEMPLATES_MAIN_TEXT"
                errorCount={getError("main", true)}
            >
                <WabaTemplateMain
                    isDisabled={isMainDisabled}
                    getValue={getValue}
                    getError={getError}
                    onChange={onChange}
                />
            </AccordionItem>

            <AccordionItem
                value="header"
                message="WABA_TEMPLATES_HEADER_TITLE"
                description="WABA_TEMPLATES_HEADER_TEXT"
                errorCount={getError("header", true)}
            >
                <WabaTemplateHead
                    clientID={clientID}
                    isDisabled={isGlobalDisabled}
                    getValue={getValue}
                    getError={getError}
                    onChange={onChange}
                    setData={setData}
                />
            </AccordionItem>

            <AccordionItem
                value="body"
                message="WABA_TEMPLATES_BODY_TITLE"
                description="WABA_TEMPLATES_BODY_TEXT"
                errorCount={getError("body", true)}
            >
                <WabaTemplateBody
                    isDisabled={isGlobalDisabled}
                    getValue={getValue}
                    getError={getError}
                    setData={setData}
                />
            </AccordionItem>

            <AccordionItem
                value="footer"
                message="WABA_TEMPLATES_FOOTER_TITLE"
                description="WABA_TEMPLATES_FOOTER_TEXT"
                errorCount={getError("footer", true)}
            >
                <WabaTemplateFoot
                    isDisabled={isGlobalDisabled}
                    getValue={getValue}
                    getError={getError}
                    onChange={onChange}
                />
            </AccordionItem>

            <AccordionItem
                value="buttons"
                message="WABA_TEMPLATES_BUTTONS_TITLE"
                description="WABA_TEMPLATES_BUTTONS_TEXT"
                errorCount={getError("buttons", true)}
            >
                <WabaTemplateButtons
                    isDisabled={isGlobalDisabled}
                    getValue={getValue}
                    getError={getError}
                    onChange={onChange}
                />
            </AccordionItem>
        </PageAccordion>

        <PageFooter>
            <Button
                isHidden={!canSaveDraft}
                variant="primary"
                message="WABA_TEMPLATES_SAVE_DRAFT"
                onClick={onDraft}
                isDisabled={loading}
            />
            <Button
                isHidden={!channels.length}
                variant="primary"
                message={shouldPublish ? "GENERAL_PUBLISH" : "GENERAL_SAVE"}
                onClick={onPublish}
                isDisabled={loading}
            />
            <Button
                isHidden={!isEdit}
                variant="primary"
                message="GENERAL_DELETE"
                onClick={onDelete}
                isDisabled={loading}
            />
            <Button
                variant="cancel"
                message="GENERAL_CANCEL"
                onClick={onClose}
            />
        </PageFooter>
    </PageContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WabaTemplateEditor.propTypes = {
    loading   : PropTypes.bool.isRequired,
    clientID  : PropTypes.number.isRequired,
    hasTabs   : PropTypes.bool.isRequired,
    getValue  : PropTypes.func.isRequired,
    getError  : PropTypes.func.isRequired,
    onChange  : PropTypes.func.isRequired,
    setData   : PropTypes.func.isRequired,
    onDraft   : PropTypes.func.isRequired,
    onPublish : PropTypes.func.isRequired,
    onDelete  : PropTypes.func.isRequired,
    onClose   : PropTypes.func.isRequired,
};

export default WabaTemplateEditor;
