import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import DashboardFilter      from "../Edit/DashboardFilter";
import DashboardPersonalize from "../Edit/DashboardPersonalize";

// Dashboard
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionList           from "Dashboard/Components/Header/ActionList";



/**
 * The Reports Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReportsHeader(props) {
    const { isHidden } = props;

    const clientID  = Commons.useClientID();
    const partnerID = Commons.usePartnerID();

    const { filters } = Store.useState("dashboardReport");
    const { initialFilter } = Store.useState("dashboardFilter");

    // The Current State
    const [ dialog, setDialog ] = React.useState("");


    // Variables
    const fullFilters = { ...initialFilter, ...(filters ?? {}) };
    const hasFilters  = !Utils.areObjectsEqual(initialFilter, fullFilters);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <ActionList>
            <ActionItem
                icon="filter"
                message="GENERAL_FILTER"
                onClick={() => setDialog("filter")}
                withMark={hasFilters}
            />
            <ActionItem
                icon="personalize"
                message="PERSONALIZE_NAME"
                onClick={() => setDialog("personalize")}
            />
        </ActionList>

        <DashboardFilter
            open={dialog === "filter"}
            type="reports"
            clientID={clientID}
            partnerID={partnerID}
            filters={filters}
            onClose={() => setDialog("")}
        />
        <DashboardPersonalize
            open={dialog === "personalize"}
            clientID={clientID}
            partnerID={partnerID}
            onClose={() => setDialog("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReportsHeader.propTypes = {
    isHidden : PropTypes.bool,
};

export default ReportsHeader;
