import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";

// Components
import HotelHeader          from "./View/HotelHeader";
import HotelEditor          from "./View/HotelEditor";
import HotelPreview         from "./View/HotelPreview";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Hotel Page
 * @returns {React.ReactElement}
 */
function HotelPage() {
    const { hotelID } = Navigate.useParams();

    const clientID = Commons.useClientID();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent();
    const goto     = parent.replace(NLS.baseUrl("CREATE"), "");
    const isEdit   = Boolean(hotelID && !parent.endsWith(NLS.baseUrl("CREATE")));

    const { charging, edition, elem } = Store.useState("hotel");
    const { fetchElem, fetchEditData, editHotel, deleteHotel } = Store.useAction("hotel");


    // The Fields
    const fields = {
        main    : [ "name", "siteUrl", "status" ],
        pms     : [ "pmsProvider", "pmsID", "pmsPassword", "pmsSystemCode" ],
        fields  : [ "field" ],
        booking : [ "bookingProvider", "bookingHotel", "bookingAccessToken", "bookingRefreshToken", "bookingUrl", "bookingPromoCode" ],
    };

    // The Current State
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        clientID            : 0,
        hotelID             : 0,
        name                : "",
        siteUrl             : "",
        status              : "Active",
        pmsProvider         : "",
        pmsID               : "",
        pmsUseFTP           : 0,
        pmsPassword         : "",
        pmsLogAll           : 0,
        pmsIsDisabled       : 0,
        fields              : {},
        bookingProvider     : "",
        bookingHotel        : "",
        bookingAccessToken  : "",
        bookingRefreshToken : "",
        bookingUrl          : "",
        bookingPromoCode    : "",
    };


    // Handles the Edit
    const handleEdit = (data) => {
        return editHotel({
            ...data,
            clientID,
            fields : JSON.stringify(data.fields),
        });
    };

    // Handles the Change
    const handleFieldChange = (name, value) => {
        const fields = { ...data.fields, [name] : value };
        handleChange("fields", fields);
    };

    // Handles the Close
    const handleClose = () => {
        navigate(goto);
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteHotel(elem.id);
        navigate(goto);
    };

    // The Form State
    const {
        loading, data, errors, setData, handleChange,
        resetData, resetErrors, handleSubmit,
    } = useForm("hotel", initialData, handleEdit, handleClose);


    // Load the Data
    React.useEffect(() => {
        if (hotelID) {
            fetchElem(hotelID);
        } else {
            fetchEditData(clientID);
        }
    }, [ hotelID ]);

    // Store the Data
    React.useEffect(() => {
        if (isEdit) {
            setData({ ...elem, ...elem.pmsProviderData });
        } else {
            resetErrors();
            resetData();
        }
    }, [ edition ]);


    // Returns the Error Count in the Step
    const getErrorCount = (step) => {
        return Commons.getErrorCount(fields, errors, step);
    };



    // Do the Render
    return <Main withNavigation>
        <HotelHeader
            data={data}
            href={goto}
            isEdit={isEdit}
        />

        <Content
            isLoading={loading || charging}
            withDetails
        >
            <HotelEditor
                isEdit={isEdit}
                data={data}
                errors={errors}
                getErrorCount={getErrorCount}
                onChange={handleChange}
                onFieldChange={handleFieldChange}
                onSubmit={handleSubmit}
                onDelete={() => setShowDelete(true)}
                onClose={handleClose}
            />
            <HotelPreview
                data={data}
            />
        </Content>

        <DeleteDialog
            open={showDelete}
            title="HOTELS_DELETE_TITLE"
            message="HOTELS_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </Main>;
}

export default HotelPage;
