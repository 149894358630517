import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const SubTitle = Styled.h2`
    margin: 0;
    text-align: center;
    font-family: var(--title-font);
    font-weight: 400;
    color: var(--black-color);
`;

const TerTitle = Styled.h3`
    margin: 0;
    text-align: center;
    font-weight: 400;
    color: var(--black-color);
`;

const Help = Styled.p`
    margin: 0;
    font-size: 14px;
`;



/**
 * The Auth Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AuthHeader(props) {
    const { title, subtitle, help } = props;


    // Do the Render
    const hasSubtitle = !!subtitle;
    const hasHelp     = !!help;

    return <>
        <SubTitle>{NLS.get(title)}</SubTitle>
        {hasSubtitle && <TerTitle>{NLS.get(subtitle)}</TerTitle>}
        {hasHelp     && <Help>{NLS.get(help)}</Help>}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AuthHeader.propTypes = {
    title    : PropTypes.string.isRequired,
    subtitle : PropTypes.string,
    help     : PropTypes.string,
};

export default AuthHeader;
