import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import { Dashboard }        from "Utils/API";



// The initial Filter
const initialFilter = {
    period       : Period.LAST_15_DAYS,
    partnerID    : [],
    clientID     : [],
    providerName : [],
    channelID    : [],
    hotelID      : [],
    userID       : [],
    onlyMine     : 0,
};

// The initial State
const initialState = {
    error         : false,
    edition       : 0,
    filters       : 0,
    partners      : [],
    clients       : [],
    idsPerPartner : {},
    providers     : [],
    channels      : [],
    hotels        : [],
    users         : [],
    initialFilter : { ...initialFilter },
};



// The Actions
const actions = {
    /**
     * Fetches the Subscription Filter data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number}   partnerID
     * @returns {Promise}
     */
    async fetchFilterData(dispatch, clientID, partnerID) {
        const data = await Dashboard.getFilterData({ clientID, partnerID });
        dispatch({ type : "DASHBOARD_FILTER", data });
    },

    /**
     * Saves the Dashboard Filters
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async saveFilters(dispatch, params) {
        await Dashboard.saveFilters(params);
        dispatch({ type : "DASHBOARD_FILTERED" });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_FILTER")) {
        return { ...state, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_FILTER":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            partners      : action.data.partners,
            clients       : action.data.clients,
            providers     : action.data.providers,
            idsPerPartner : action.data.idsPerPartner,
            channels      : action.data.channels,
            hotels        : action.data.hotels,
            users         : action.data.users,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
