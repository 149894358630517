import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    Campaign, CampaignTrigger,
    CampaignCondition, CampaignGroup,
} from "Utils/API";



// The initial State
const initialState = {
    loading         : true,
    charging        : false,
    error           : false,
    edition         : 0,
    canCreate       : false,
    canEdit         : false,
    canAddChannel   : false,
    canImport       : false,
    canExport       : false,
    list            : [],
    total           : 0,
    statuses        : [],
    elem            : {},
    activateErrors  : {},
    hasEmail        : false,
    hasSMS          : false,
    hasWhatsApp     : false,
    maxButtons      : 0,
    buttonLength    : 0,
    smsCost         : 0,
    options         : {},
    optionsDraft    : {},
    triggers        : [],
    triggersDraft   : [],
    triggersOptions : [],
    conditions      : [],
    conditionsDraft : [],
    groups          : [],
    groupsDraft     : [],
    selects       : {
        triggers           : [],
        conditions         : [],
        conversationFields : [],
        contactFields      : [],
        contactDateFields  : [],
        hospitalityFields  : [],
        tongues            : [],
        tags               : [],
        hotels             : [],
        teams              : [],
        whatsAppChannels   : [],
        smsChannels        : [],
        emailChannels      : [],
        channelLinks       : [],
        variables          : [],
        wabaTemplates      : [],
    },
    sort            : {
        filter   : "Active",
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CAMPAIGN_LOADING" });
    },

    /**
     * Fetches the Campaign List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data = await Campaign.getAll(params);
        data.sort  = params;
        dispatch({ type : "CAMPAIGN_LIST", data });
    },

    /**
     * Fetches a single Campaign
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    async fetchElem(dispatch, campaignID) {
        dispatch({ type : "CAMPAIGN_CHARGING" });
        const data = await Campaign.getOne({ campaignID });
        dispatch({ type : "CAMPAIGN_ELEM", data });
        dispatch({ type : "FLOW_EDITOR_CAMPAIGN", data });
        return data;
    },

    /**
     * Creates a Campaign
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {String}   name
     * @returns {Promise}
     */
    createCampaign(dispatch, clientID, name) {
        return Campaign.create({ clientID, name });
    },

    /**
     * Activates a Campaign
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    activateCampaign(dispatch, campaignID) {
        return Campaign.activate({ campaignID });
    },

    /**
     * Pauses a Campaign
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    pauseCampaign(dispatch, campaignID) {
        return Campaign.pause({ campaignID });
    },

    /**
     * Completes a Campaign
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    completeCampaign(dispatch, campaignID) {
        return Campaign.complete({ campaignID });
    },

    /**
     * Publishes a Campaign
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    publishCampaign(dispatch, campaignID) {
        return Campaign.publish({ campaignID });
    },

    /**
     * Clears a Campaign
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    clearCampaign(dispatch, campaignID) {
        return Campaign.clear({ campaignID });
    },

    /**
     * Deletes a Campaign
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    deleteCampaign(dispatch, campaignID) {
        return Campaign.delete({ campaignID });
    },


    /**
     * Edits a Campaign Option
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @param {String}   option
     * @param {*}        value
     * @param {Number}   tongueID
     * @returns {Promise}
     */
    async editOption(dispatch, campaignID, option, value, tongueID) {
        const data = await Campaign.editOption({ campaignID, option, value, tongueID });
        if (!data.errors) {
            dispatch({ type : "CAMPAIGN_EDITED", data });
            dispatch({ type : "FLOW_EDITOR_CAMPAIGN", data });
        }
        return data;
    },

    /**
     * Creates a Campaign Trigger
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async createTrigger(dispatch, params) {
        const data = await CampaignTrigger.create(params);
        if (!data.errors) {
            dispatch({ type : "CAMPAIGN_TRIGGER", data });
        }
        return data;
    },

    /**
     * Edits a Campaign Trigger
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editTrigger(dispatch, params) {
        const data = await CampaignTrigger.edit(params);
        if (!data.errors) {
            dispatch({ type : "CAMPAIGN_TRIGGER", data });
        }
        return data;
    },

    /**
     * Deletes a Campaign Trigger
     * @param {Function} dispatch
     * @param {Number}   triggerID
     * @returns {Promise}
     */
    async deleteTrigger(dispatch, triggerID) {
        const data = await CampaignTrigger.delete({ triggerID });
        if (!data.error) {
            dispatch({ type : "CAMPAIGN_TRIGGER", data });
        }
        return data;
    },

    /**
     * Creates a Campaign Condition
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async createCondition(dispatch, params) {
        const data = await CampaignCondition.create(params);
        if (!data.errors) {
            dispatch({ type : "CAMPAIGN_CONDITION", data });
        }
        return data;
    },

    /**
     * Edits a Campaign Condition
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async editCondition(dispatch, params) {
        const data = await CampaignCondition.edit(params);
        if (!data.errors) {
            dispatch({ type : "CAMPAIGN_CONDITION", data });
        }
        return data;
    },

    /**
     * Deletes a Campaign Condition
     * @param {Function} dispatch
     * @param {Number}   conditionID
     * @returns {Promise}
     */
    async deleteCondition(dispatch, conditionID) {
        const data = await CampaignCondition.delete({ conditionID });
        if (!data.error) {
            dispatch({ type : "CAMPAIGN_CONDITION", data });
        }
        return data;
    },

    /**
     * Creates a Campaign Condition Group
     * @param {Function} dispatch
     * @param {Number}   campaignID
     * @returns {Promise}
     */
    async createGroup(dispatch, campaignID) {
        const data = await CampaignGroup.create({ campaignID });
        if (!data.error) {
            dispatch({ type : "CAMPAIGN_CONDITION", data });
        }
        return data;
    },

    /**
     * Edits a Campaign Condition Group
     * @param {Function} dispatch
     * @param {Number}   groupID
     * @param {String}   match
     * @returns {Promise}
     */
    async editGroup(dispatch, groupID, match) {
        const data = await CampaignGroup.edit({ groupID, match });
        if (!data.error) {
            dispatch({ type : "CAMPAIGN_CONDITION", data });
        }
        return data;
    },

    /**
     * Deletes a Campaign Condition Group
     * @param {Function} dispatch
     * @param {Number}   groupID
     * @returns {Promise}
     */
    async deleteGroup(dispatch, groupID) {
        const data = await CampaignGroup.delete({ groupID });
        if (!data.error) {
            dispatch({ type : "CAMPAIGN_CONDITION", data });
        }
        return data;
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.startDate         = DateTime.formatDate(elem.startTime, "dashes");
    elem.endDate           = DateTime.formatIf(elem.endTime, "dashes");
    elem.lastExecutionDate = DateTime.formatIf(elem.lastExecutionTime, "dashes");
    elem.nextExecutionDate = DateTime.formatIf(elem.nextExecutionTime, "dashes");
    return elem;
}

/**
 * Parses the Errors
 * @param {Object} errors
 * @returns {Object}
 */
function parseErrors(errors) {
    const channelErrors = (
        Number(errors.channels || 0) +
        Number(errors.email    || 0) +
        Number(errors.sms      || 0) +
        Number(errors.whatsApp || 0)
    );
    if (channelErrors > 0) {
        errors.channels = channelErrors;
    }
    return errors;
}

/**
 * Parses the Options
 * @param {Object} options
 * @returns {Object}
 */
function parseOptions(options) {
    for (const [ tongueID, tongueOptions ] of Object.entries(options)) {
        for (const [ option, value ] of Object.entries(tongueOptions)) {
            if (option === "startTime" || option === "endTime") {
                if (!Number(value)) {
                    options[tongueID][option] = "";
                } else {
                    options[tongueID][option] = DateTime.formatDate(Number(value), "dashesReverse");
                }
            }
        }
    }
    return options;
}

/**
 * Parses the Dates
 * @param {Array<Object>} list
 * @returns {Object}
 */
function parseDates(list) {
    for (const elem of list) {
        elem.date = DateTime.formatIf(elem.date, "dashesReverse");
    }
    return list;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CAMPAIGN_LIST", "CAMPAIGN_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CAMPAIGN_LOADING":
        return {
            ...state,
            loading         : true,
        };
    case "CAMPAIGN_CHARGING":
        return {
            ...state,
            charging        : true,
        };

    case "CAMPAIGN_LIST":
        return {
            ...state,
            loading         : false,
            error           : false,
            canCreate       : action.data.canCreate,
            canEdit         : action.data.canEdit,
            canImport       : action.data.canImport,
            canExport       : action.data.canExport,
            list            : Utils.parseList(action.data.list, parseElem),
            total           : action.data.total,
            sort            : action.data.sort,
            statuses        : action.data.statuses,
        };

    case "CAMPAIGN_ELEM":
        return {
            ...state,
            charging        : false,
            error           : false,
            edition         : state.edition + 1,
            canEdit         : action.data.canEdit,
            canAddChannel   : action.data.canAddChannel,
            elem            : parseElem(action.data.elem),
            activateErrors  : parseErrors(action.data.activateErrors),
            hasEmail        : action.data.hasEmail,
            hasSMS          : action.data.hasSMS,
            hasWhatsApp     : action.data.hasWhatsApp,
            maxButtons      : action.data.maxButtons,
            buttonLength    : action.data.buttonLength,
            smsCost         : action.data.smsCost,
            options         : parseOptions(action.data.options),
            optionsDraft    : parseOptions(action.data.optionsDraft),
            triggers        : parseDates(action.data.triggers),
            triggersDraft   : parseDates(action.data.triggersDraft),
            conditions      : parseDates(action.data.conditions),
            conditionsDraft : parseDates(action.data.conditionsDraft),
            groups          : action.data.groups,
            groupsDraft     : action.data.groupsDraft,
            selects         : action.data.selects,
        };

    case "CAMPAIGN_EDITED":
        return {
            ...state,
            elem            : parseElem(action.data.elem),
            activateErrors  : parseErrors(action.data.activateErrors),
        };

    case "CAMPAIGN_TRIGGER":
        return {
            ...state,
            elem            : { ...state.elem, needsPublish : true },
            triggersDraft   : parseDates(action.data.triggersDraft),
            activateErrors  : parseErrors(action.data.activateErrors),
        };

    case "CAMPAIGN_CONDITION":
        return {
            ...state,
            elem            : { ...state.elem, needsPublish : true },
            groupsDraft     : action.data.groupsDraft,
            conditionsDraft : parseDates(action.data.conditionsDraft),
            activateErrors  : parseErrors(action.data.activateErrors),
        };

    case "FLOW_EDITOR_ELEM":
        return {
            ...state,
            elem            : { ...state.elem, needsPublish : action.data.elem.needsPublish },
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
