import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import QRCode               from "react-qr-code";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import MessageDay           from "Components/App/Client/Conversation/Message/MessageDay";
import MessageBubble        from "Components/App/Client/Conversation/Message/MessageBubble";
import ChannelIcon          from "Components/Utils/Common/ChannelIcon";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import IconLink             from "Dashboard/Components/Link/IconLink";
import Icon                 from "Dashboard/Components/Common/Icon";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.main.attrs(({ hasTabs }) => ({ hasTabs }))`
    --widget-tabs: ${(props) => props.hasTabs ? "var(--tabs-table)" : "0px"};
    --widget-height: calc(var(--page-height) - 16px - var(--widget-tabs) - 94px - 2px);

    --widget-font: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
    --widget-header-bg: var(--primary-color);
    --widget-header-text: white;
    --widget-button-bg: var(--primary-color);
    --widget-button-icon: white;
    --widget-icon-bg: var(--primary-color);
    --widget-icon-icon: white;
    --widget-icon-border: white;
    --widget-bubble-bg: white;
    --widget-bubble-text: var(--primary-color);
    --widget-bubble-border: var(--primary-color);

    width: var(--widget-width);
`;

const Tabs = Styled(TabList)`
    justify-content: center;
`;

const Popup = Styled.div.attrs({ className : "no-scrollbars" })`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 0 20px 0 rgb(104 121 146 / 20%);
    font-family: var(--widget-font);
    color: var(--black-color);
    max-height: var(--widget-height);
    overflow: auto;
`;

const CloseIcon = Styled(Icon)`
    position: absolute;
    top: 6px;
    right: 6px;
    color: var(--widget-header-text);
    font-size: 18px;
    z-index: 3;
    cursor: pointer;
`;

const Header = Styled.header.attrs(({ leftAlign, hasIcon, forStart }) => ({ leftAlign, hasIcon, forStart }))`
    box-sizing: border-box;
    position: sticky;
    top: 0;
    display: flex;
    flex-shrink: 0;
    justify-content: ${(props) => props.leftAlign ? "flex-start" : "center"};
    align-items: center;
    gap: 10px;
    min-height: 70px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    color: var(--widget-header-text);
    background-color: var(--widget-header-bg);

    ${(props) => props.forStart ? `
        padding: 24px 30px 84px 30px;
    ` : `
        padding: ${props.hasIcon ? "24px 40px" : "24px 30px"};
    `}
`;

const HeaderLogo = Styled.img`
    max-height: 50px;
`;

const HeaderIcon = Styled(Icon)`
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    cursor: pointer;
`;

const Start = Styled.main`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 16px;
    min-height: 60px;
    margin: -60px 24px 0;
    padding: 24px;
    background-color: white;
    border-radius: 16px;
    box-shadow: var(--box-shadow);
    z-index: 2;
`;

const Section = Styled.section`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
`;

const Title = Styled.h3`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    color: #24374e;
    font-size: 18px;
    font-weight: 600;

    &:empty {
        display: none;
    }
    .icon {
        cursor: pointer;
    }
`;

const Inputs = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Input = Styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 8px 8px 8px 12px;
    border: 1px solid #d9e1ec;
    border-radius: 8px;
    color: #687992;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        border-color: black;
    }
`;

const NameInput = Styled.div`
    padding: 2px 0;
`;

const TextInput = Styled.div`
    flex-grow: 2;
    padding: 2px 0;
`;

const StartButton = Styled.div`
    background-color: var(--widget-button-bg);
    color: var(--widget-button-icon);
    text-align: center;
    font-size: 14px;
    padding: 10px 8px;
    border-radius: 8px;
    border: 1px solid var(--widget-button-bg);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        background-color: white;
        color: black;
    }
`;

const InputIcon = Styled(Icon)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
`;

const MainIcon = Styled(InputIcon)`
    background-color: var(--widget-button-bg);
    color: var(--widget-button-icon);
`;

const SecondaryIcon = Styled(InputIcon)`
    background-color: #f1f3f8;
    color: var(--black-color);
`;

const Message = Styled.div`
    padding: 12px 16px;
    border: 1px solid #d9e1ec;
    border-radius: 8px;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        border-color: black;
    }
`;

const MessageDate = Styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 12px;
`;

const MessageText = Styled.p`
    margin: 8px 0 0 0;
`;

const Platform = Styled.div.attrs(({ color }) => ({ color }))`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px;
    border: 1px solid #d9e1ec;
    border-radius: 8px;
    transition: all 0.2s;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
    &:hover {
        background-color: ${(props) => props.color};
        border-color: ${(props) => props.color};
        color: white;
    }
    .icon {
        border-radius: 8px;
    }
`;

const WebLink = Styled.a`
    padding: 12px 16px;
    border: 1px solid #d9e1ec;
    border-radius: 8px;
    transition: all 0.2s;
    text-align: center;
    cursor: pointer;

    &:empty {
        display: none;
    }
    &:hover {
        border-color: black;
    }
`;

const Code = Styled(QRCode)`
    height: auto;
    width: calc(100% - 32px * 2);
    padding: 16px 32px;
    border: 1px solid #d9e1ec;
    border-radius: 8px;
`;

const Chat = Styled.div.attrs({ className : "no-scrollbars" })`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
    overflow: auto;
`;

const Day = Styled(MessageDay)`
    color: var(--widget-header-text);
    background-color: var(--widget-header-bg);
    border-radius: 8px;
`;

const Messages = Styled.section.attrs(({ isMine }) => ({ isMine }))`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.isMine ? "flex-end" : "flex-start"};
    margin: 0 8px;
`;

const User = Styled.h3`
    margin: 0;
    padding: 2px 24px;
    font-size: 14px;
`;

const Footer = Styled.footer`
    position: relative;
`;

const Branding = Styled(Html)`
    display: block;
    padding: 8px 5px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: #91989e;
`;

const ConfigIcon = Styled(Icon)`
    position: absolute;
    bottom: 4px;
    right: 8px;
    font-size: 18px;
    cursor: pointer;
`;

const Send = Styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 6px 6px 6px 12px;
    border-top: 1px solid #d9e1ec;
    color: #91989e;
`;

const Bubble = Styled.div`
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    margin: 10px 0;
    padding: 0 8px;
    gap: 15px;
`;

const BubbleIcon = Styled.div.attrs(({ hasIcon, iconSize, showBorder, showShadow }) => ({ hasIcon, iconSize, showBorder, showShadow }))`
    flex-shrink: 0;
    box-sizing: border-box;
    width: ${(props) => props.iconSize}px;
    height: ${(props) => props.iconSize}px;
    cursor: pointer;

    ${(props) => props.showBorder && `
        border: 2px solid var(--widget-icon-border);
    `}
    ${(props) => props.showShadow && `
        box-shadow: 3px 3px 8px rgb(180, 180, 180);
    `}
    ${(props) => (props.hasIcon || props.showBorder || props.showShadow) && `
        border-radius: 50%;
        overflow: hidden;
    `}
`;

const BubbleImage = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const BubbleIcono = Styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--widget-icon-bg);
`;

const BubbleSVG = Styled.svg`
    display: block;
    width: 65%;
    fill: var(--widget-icon-icon);
`;

const BubbleText = Styled.div`
    display: flex;
    align-items: center;
`;

const BubbleInside = Styled.p`
    position: relative;
    margin: 0;
    padding: 5px 10px;
    color: var(--widget-bubble-text);
    background-color: var(--widget-bubble-bg);
    border: 1px solid var(--widget-bubble-border);
    border-radius: 8px;
    font-family: var(--widget-font);
    font-size: 14px;

    &:after {
        content: "";
        position: absolute;
        right: -10px;
        top: calc(50% - 6px);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 10px solid var(--widget-bubble-border);
    }
`;

const BubbleCount = Styled.div`
    position: absolute;
    top: 0;
    right: 8px;
    font-size: 13px;
    min-width: 18px;
    line-height: 18px;
    font-size: 10px;
    text-align: center;
    border-radius: 999px;
    color: white;
    background-color: #c70b3e;
`;

const Goto = Styled(IconLink)`
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 1;
`;

const HeaderGoto = Styled(IconLink)`
    position: absolute;
    top: 28px;
    right: 4px;
`;



/**
 * The Widget Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetPreview(props) {
    const { className, options, platforms, getText, isPublish } = props;

    const navigate = Commons.useGoto();

    const { elem, tongues } = Store.useState("widget");


    // Gets the current Tabs
    const getTabs = () => {
        const tabs = [];
        if (hasStart) {
            tabs.push("start");
        }
        if (hasRestart) {
            tabs.push("restart");
        }
        if (hasQRCode) {
            tabs.push("platform");
        }
        if (hasChat) {
            tabs.push("chat");
        }
        return tabs;
    };


    // The Current State
    const [ tab, setTab ] = React.useState("start");

    // Variables
    const hasPlatforms       = Utils.isActive(options.showPlatforms);
    const showPlatforms      = hasPlatforms && platforms.length > 0;
    const platformsWithQR    = platforms.filter(({ showQR }) => showQR);

    const hasStart           = Utils.isActive(options.showStart);
    const hasChat            = Utils.isActive(options.showChat);
    const hasRestart         = hasStart && hasChat;
    const hasQRCode          = hasStart && showPlatforms && platformsWithQR.length > 0;

    const tabs               = getTabs();
    const showTabs           = tabs.length > 1;
    const currTab            = tabs.includes(tab) ? tab : tabs[0];

    const fontFamily         = options.fontFamily || "Lato";
    const headerLeftAlign    = Utils.isActive(options.headerLeftAlign);
    const logoFile           = options.logoFile ? `${process.env.REACT_APP_FILES}${elem.clientID}/${options.logoFile}` : "";
    const showChatLogo       = !!logoFile && Utils.isActive(options.showChatLogo);

    const showReqName        = Utils.isActive(options.requestName);
    const showReqMessage     = Utils.isActive(options.requestMessage);
    const showBigButton      = Utils.isActive(options.bigStartButton);
    const showEmojiButton    = Utils.isActive(options.showEmojiButton);
    const showFileButton     = Utils.isActive(options.showFileButton);
    const showLocationButton = Utils.isActive(options.showLocationButton);
    const showNameButton     = !showBigButton && showReqName && !showReqMessage;
    const showMessageButton  = !showBigButton && showReqMessage;
    const showStartButton    = showBigButton || (!showReqName && !showReqMessage);
    const showNames          = Boolean(options.showNames && options.showNames !== "none");
    const showFullNames      = Boolean(options.showNames === "fullName");
    const qrPlatform         = hasQRCode ? platformsWithQR[0] : {};
    const showStart          = currTab === "start" || currTab === "restart";
    const showInput          = hasChat && currTab === "start";
    const showRestart        = currTab === "restart";
    const showPlatform       = currTab === "platform";
    const showChat           = currTab === "chat";
    const showConfig         = tongues.length > 1;
    const botName            = getText("botName") || NLS.get("WIDGETS_CHAT_YOURS_BOT");
    const myName             = NLS.get(`WIDGETS_CHAT_MINE_NAME${showFullNames ? "_FULL" : ""}`);
    const yourName           = NLS.get(`WIDGETS_CHAT_YOURS_NAME${showFullNames ? "_FULL" : ""}`);
    const iconSize           = Utils.clamp(Number(options.iconSize || 70), 30, 200);
    const iconShowFile       = Utils.isActive(options.iconShowFile);
    const iconShowBorder     = Utils.isActive(options.iconShowBorder);
    const iconShowShadow     = Utils.isActive(options.iconShowShadow);
    const bubbleIconFile     = iconShowFile && options.iconFile ? `${process.env.REACT_APP_FILES}${elem.clientID}/${options.iconFile}` : "";
    const showMessageCount   = Utils.isActive(options.showMessageCount);
    const bubbleText         = getText("bubbleText");
    const showBubble         = Utils.isActive(options.showBubble) && !!bubbleText;


    // Goes to the given Url
    const gotoUrl = (url) => {
        navigate("SETTINGS", "WIDGETS", elem.id, url);
    };

    // The custom colors
    const style = {
        "--widget-font"          : `${fontFamily}, "Helvetica Neue", Helvetica, Arial, sans-serif`,
        "--widget-header-bg"     : options.headerBgColor,
        "--widget-header-text"   : options.headerTextColor,
        "--widget-button-bg"     : options.buttonBgColor,
        "--widget-button-icon"   : options.buttonIconColor,
        "--widget-icon-bg"       : options.iconBgColor,
        "--widget-icon-icon"     : options.iconIconColor,
        "--widget-icon-border"   : options.iconBorderColor,
        "--widget-bubble-bg"     : options.bubbleBgColor,
        "--widget-bubble-border" : options.bubbleBorderColor,
        "--widget-bubble-text"   : options.bubbleTextColor,

        "--bubble-mine-bg"       : options.contactMessageBgColor,
        "--bubble-mine-color"    : options.contactMessageTextColor,
        "--bubble-your-bg"       : options.userMessageBgColor,
        "--bubble-your-color"    : options.userMessageTextColor,
    };


    // Do the Render
    // @ts-ignore
    return <Container hasTabs={showTabs} className={className} style={style}>
        <Tabs isHidden={!showTabs} selected={currTab} onClick={setTab}>
            <TabItem message="WIDGETS_START"              value="start"    isHidden={!hasStart}   />
            <TabItem message="WIDGETS_RESTART"            value="restart"  isHidden={!hasRestart} />
            <TabItem message="WIDGETS_PLATFORMS_SINGULAR" value="platform" isHidden={!hasQRCode}  />
            <TabItem message="WIDGETS_CHAT"               value="chat"     isHidden={!hasChat}    />
        </Tabs>

        {showStart && <Popup>
            <Header leftAlign={headerLeftAlign} forStart>
                <CloseIcon icon="cancel" />
                {!!logoFile && <HeaderLogo src={logoFile} />}
                {getText("headerText")}
                <HeaderGoto
                    isHidden={isPublish}
                    variant="black"
                    icon="edit"
                    onClick={() => gotoUrl("MAIN")}
                    isSmall
                />
            </Header>
            <Start>
                {showInput && <Section>
                    <Goto
                        isHidden={isPublish}
                        variant="black"
                        icon="edit"
                        onClick={() => gotoUrl("CHAT")}
                        isSmall
                    />
                    <Title>{getText("conversationText")}</Title>
                    <Inputs>
                        {showReqName && <Input>
                            <NameInput>
                                {getText("requestNameText")}
                            </NameInput>
                            {showNameButton && <MainIcon icon="send" />}
                        </Input>}
                        {showReqMessage && <Input>
                            <TextInput>
                                {getText("requestMessageText")}
                            </TextInput>
                            {showMessageButton && <MainIcon icon="send" />}
                        </Input>}
                        {showStartButton && <StartButton>
                            {getText("startButtonText")}
                        </StartButton>}
                    </Inputs>
                </Section>}

                {showRestart && <Section>
                    <Goto
                        isHidden={isPublish}
                        variant="black"
                        icon="edit"
                        onClick={() => gotoUrl("CHAT")}
                        isSmall
                    />
                    <Title>{getText("restartText")}</Title>
                    <Message>
                        <MessageDate>
                            {Commons.getDayString(Utils.getCurrentTime())}
                        </MessageDate>
                        <MessageText>
                            {showNames && <b>{yourName}:&nbsp;</b>}
                            {NLS.get("WIDGETS_CHAT_YOURS_THIRD")}
                        </MessageText>
                    </Message>
                </Section>}

                {showPlatforms && <Section>
                    <Goto
                        isHidden={isPublish}
                        variant="black"
                        icon="edit"
                        onClick={() => gotoUrl("PLATFORMS")}
                        isSmall
                    />
                    <Title>{getText("platformsText")}</Title>
                    <div>
                        {platforms.map((item) => <Platform
                            key={item.id}
                            color={item.color}
                        >
                            <ChannelIcon
                                icon={item.icon}
                                color={item.color}
                                size={24}
                            />
                            <span>{item.name}</span>
                        </Platform>)}
                    </div>
                </Section>}
            </Start>
            <Footer>
                <Branding>{NLS.get("WIDGETS_BRANDING")}</Branding>
                {showConfig && <ConfigIcon icon="settings" />}
            </Footer>
        </Popup>}

        {showPlatform && <Popup>
            <Header leftAlign={headerLeftAlign} forStart>
                <CloseIcon icon="cancel" />
                {!!logoFile && <HeaderLogo src={logoFile} />}
                {getText("headerText")}
                <HeaderGoto
                    isHidden={isPublish}
                    variant="black"
                    icon="edit"
                    onClick={() => gotoUrl("MAIN")}
                    isSmall
                />
            </Header>
            <Start>
                <Title>
                    <Icon icon="back" />
                    {`${getText("connectText")} `}
                    {qrPlatform.name}
                    <Goto
                        isHidden={isPublish}
                        variant="black"
                        icon="edit"
                        onClick={() => gotoUrl("PLATFORMS")}
                        isSmall
                    />
                </Title>
                <WebLink>
                    {getText("webLinkText")}
                </WebLink>
                <Title>
                    {getText("qrCodeText")}
                </Title>
                <Code
                    size={256}
                    value={qrPlatform.url}
                />
            </Start>
            <Footer>
                <Branding>{NLS.get("WIDGETS_BRANDING")}</Branding>
                {showConfig && <ConfigIcon icon="settings" />}
            </Footer>
        </Popup>}

        {showChat && <Popup>
            <Header leftAlign={headerLeftAlign} hasIcon={hasStart}>
                <CloseIcon icon="cancel" />
                {hasStart && <HeaderIcon icon="back" />}
                {showChatLogo && <HeaderLogo src={logoFile} />}
                {getText("chatText")}
                <HeaderGoto
                    isHidden={isPublish}
                    variant="black"
                    icon="edit"
                    onClick={() => gotoUrl("CHAT")}
                    isSmall
                />
            </Header>
            <Chat>
                <Day dayName={(new Date()).toLocaleString("es", { month : "short", day : "numeric" })} />
                <Messages isMine>
                    {showNames && <User>{myName}</User>}
                    <MessageBubble
                        message={NLS.get("WIDGETS_CHAT_MINE_FIRST")}
                        time="12:00"
                        isDelivered
                        isMine
                    />
                </Messages>
                <Messages>
                    {showNames && <User>{botName}</User>}
                    <MessageBubble
                        message={NLS.get("WIDGETS_CHAT_YOURS_FIRST")}
                        time="12:01"
                    />
                </Messages>
                <Messages isMine>
                    {showNames && <User>{myName}</User>}
                    <MessageBubble
                        message={NLS.get("WIDGETS_CHAT_MINE_SECOND")}
                        time="12:02"
                        isDelivered
                        isMine
                    />
                </Messages>
                <Messages>
                    {showNames && <User>{botName}</User>}
                    <MessageBubble
                        message={NLS.get("WIDGETS_CHAT_YOURS_SECOND")}
                        time="12:03"
                    />
                </Messages>
                <Messages>
                    {showNames && <User>{yourName}</User>}
                    <MessageBubble
                        message={NLS.get("WIDGETS_CHAT_YOURS_THIRD")}
                        time="12:05"
                    />
                </Messages>
            </Chat>
            <Branding>{NLS.get("WIDGETS_BRANDING")}</Branding>
            <Send>
                <TextInput>{getText("sendText")}</TextInput>
                {showEmojiButton && <SecondaryIcon icon="emoji" />}
                {showFileButton && <SecondaryIcon icon="attachment" />}
                {showLocationButton && <SecondaryIcon icon="address" />}
                <MainIcon icon="send" />
            </Send>
        </Popup>}

        <Bubble>
            <BubbleIcon
                hasIcon={!bubbleIconFile}
                iconSize={iconSize}
                showBorder={iconShowBorder}
                showShadow={iconShowShadow}
            >
                {bubbleIconFile ? <BubbleImage
                    src={bubbleIconFile}
                /> : <BubbleIcono>
                    <BubbleSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.232 373.232">
                        <path d="M187.466 0h-.6c-101.2 0-183.5 82.3-183.5 183.5 0 41.3 14.1 81.4 39.9 113.7l-26.7 62.1c-2.2 5.1.2 11 5.2 13.1 1.8.8 3.8 1 5.7.7l97.9-17.2c19.6 7.1 40.2 10.7 61 10.6 101.2 0 183.5-82.3 183.5-183.5.2-100.9-81.5-182.9-182.4-183zm-62.8 146.6h54c5.5 0 10 4.5 10 10s-4.5 10-10 10h-54c-5.5 0-10-4.5-10-10s4.5-10 10-10zm124 70h-124c-5.5 0-10-4.5-10-10s4.5-10 10-10h124c5.5 0 10 4.5 10 10s-4.5 10-10 10z" />
                    </BubbleSVG>
                </BubbleIcono>}
            </BubbleIcon>

            {showBubble && <BubbleText>
                <BubbleInside>{bubbleText}</BubbleInside>
            </BubbleText>}
            {showMessageCount && <BubbleCount>3</BubbleCount>}

            <Goto
                isHidden={isPublish}
                variant="black"
                icon="edit"
                onClick={() => gotoUrl("ICON")}
                isSmall
            />
        </Bubble>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetPreview.propTypes = {
    className : PropTypes.string,
    options   : PropTypes.object.isRequired,
    platforms : PropTypes.array.isRequired,
    getText   : PropTypes.func.isRequired,
    isPublish : PropTypes.bool,
};

export default WidgetPreview;
