import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import DashboardFilter      from "../Edit/DashboardFilter";

// Dashboard
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionList           from "Dashboard/Components/Header/ActionList";



/**
 * The Users Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UsersHeader(props) {
    const { isHidden } = props;

    const { filters } = Store.useState("dashboardUser");
    const { initialFilter } = Store.useState("dashboardFilter");

    // The Current State
    const [ openFilter, setOpenFilter ] = React.useState(false);


    // Variables
    const fullFilters = { ...initialFilter, ...(filters ?? {}) };
    const hasFilters  = !Utils.areObjectsEqual(initialFilter, fullFilters);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <ActionList>
            <ActionItem
                icon="filter"
                message="GENERAL_FILTER"
                onClick={() => setOpenFilter(true)}
                withMark={hasFilters}
            />
        </ActionList>

        <DashboardFilter
            open={openFilter}
            type="users"
            filters={filters}
            onClose={() => setOpenFilter(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UsersHeader.propTypes = {
    isHidden : PropTypes.bool,
};

export default UsersHeader;
