import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import useList              from "Dashboard/Hooks/List";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Monitoring Content
 * @returns {React.ReactElement}
 */
function MonitoringContent() {
    const { load, loadFilter } = useList("dashboardMonitoring");

    const navigate = Navigate.useGoto();

    const data = Store.useState("dashboardMonitoring");
    const { loading, list, filters, sort, pmsProviders } = data;

    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Start and clear the Auto-update
    React.useEffect(() => {
        Utils.setUpdateTimeout(timerRef, setUpdate);
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Handle the Update
    React.useEffect(() => {
        loadFilter(filters, false);
        Utils.setUpdateTimeout(timerRef, setUpdate, update);
    }, [ update ]);


    // Handles the Action
    const handleAction = (action, elemID) => {
        const clientID = Utils.getValue(list, "hotelID", elemID, "clientID");
        if (action.isClient) {
            navigate("CLIENTS", clientID, "HOSPITALITIES");
        } else if (action.isHotel) {
            navigate("CLIENTS", clientID, "SETTINGS", "HOTELS", elemID);
        }
    };


    // Do the Render
    return <>
        <FilterList onFilter={loadFilter} values={filters}>
            <FilterItem
                type="select"
                name="pmsProvider"
                label="HOTELS_PMS_PROVIDER"
                options={pmsProviders}
            />
            <FilterItem
                type="date"
                name="fromDate"
                label="GENERAL_FROM_DATE"
                hasClear
            />
            <FilterItem
                type="date"
                name="toDate"
                label="GENERAL_TO_DATE"
                hasClear
            />
        </FilterList>

        <Table
            fetch={load}
            sort={sort}
            none="MONITORING_NONE_AVAILABLE"
            isLoading={loading}
            hasFilter
            hasTabs
            noSorting
            noClick
        >
            <TableHead>
                <TableHeader field="clientName"      message="CLIENTS_SINGULAR"       />
                <TableHeader field="hotelName"       message="HOTELS_SINGULAR"        />
                <TableHeader field="pmsProviderName" message="HOTELS_PMS_PROVIDER"    />
                <TableHeader field="pmsID"           message="HOTELS_PMS_ID"          />
                <TableHeader field="totalPMSChanges" message="MONITORING_CHANGES"     maxWidth="120" align="center" />
                <TableHeader field="totalPMSUpdates" message="MONITORING_UPDATES"     maxWidth="120" align="center" />
                <TableHeader field="lastPMSUpdate"   message="HOTELS_PMS_LAST_UPDATE" maxWidth="180" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.hotelID} elemID={elem.hotelID}>
                    <TableCell message={elem.clientName}        />
                    <TableCell message={elem.hotelName}         />
                    <TableCell message={elem.pmsProviderName}   />
                    <TableCell message={elem.pmsID}             />
                    <TableCell message={elem.totalPMSChanges}   />
                    <TableCell message={elem.totalPMSUpdates}   />
                    <TableCell message={elem.lastPMSUpdateDate} />
                </TableRow>)}
            </TableBody>
            <TableActionList onAction={handleAction}>
                <TableAction action="CLIENT" message="CLIENTS_VIEW_TITLE" />
                <TableAction action="HOTEL"  message="HOTELS_VIEW_TITLE"  />
            </TableActionList>
        </Table>
    </>;
}

export default MonitoringContent;
