import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Subscription SMS View
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionSMS(props) {
    const { isHidden, elem } = props;


    // Do the Render
    if (isHidden || !elem.id || !elem.sms.length) {
        return <React.Fragment />;
    }
    return <Table noSorting notFixed rightSpace>
        <TableHead>
            <TableHeader field="channelName" message="CHANNELS_SINGULAR"            isTitle />
            <TableHeader field="messages"    message="SUBSCRIPTIONS_TOTAL_MESSAGES" align="center" />
            <TableHeader field="segments"    message="SUBSCRIPTIONS_TOTAL_SEGMENTS" align="center" />
            <TableHeader field="costFormat"  message="SUBSCRIPTIONS_TOTAL_COST"     align="center" rightSpace />
        </TableHead>
        <TableBody>
            {elem.sms.map((sms) => <TableRow key={sms.channelID} elemID={sms.channelID}>
                <TableCell message={sms.channelName}         />
                <TableCell message={sms.messages}            />
                <TableCell message={sms.segments}            />
                <TableCell message={`US$ ${sms.costFormat}`} />
            </TableRow>)}
        </TableBody>
    </Table>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionSMS.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    elem     : PropTypes.object.isRequired,
};

export default SubscriptionSMS;
