import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import AdminRouter          from "Components/Core/Admin/AdminRouter";
import AdminMenu            from "Components/Core/Admin/AdminMenu";
import PartnerMenu          from "Components/Core/Partner/PartnerMenu";
import PartnerRouter        from "Components/Core/Partner/PartnerRouter";
import UserRouter           from "Components/Core/User/UserRouter";
import UserMenu             from "Components/Core/User/UserMenu";
import CredentialRouter     from "Components/Core/Credential/CredentialRouter";
import CredentialMenu       from "Components/Core/Utils/CredentialMenu";
import HelpMenu             from "Components/Core/Utils/HelpMenu";
import ChatSupport          from "Components/Core/Utils/ChatSupport";

// Dashboard
import NotificationsBar     from "Components/App/Profile/Notifications/NotificationsBar";
import Container            from "Dashboard/Components/Core/Container";
import TopBar               from "Dashboard/Components/Core/TopBar";
import SideBar              from "Dashboard/Components/Core/SideBar";
import Inside               from "Dashboard/Components/Core/Inside";
import BarIcon              from "Dashboard/Components/Core/BarIcon";
import Menu                 from "Dashboard/Components/Menu/Menu";

// Images
import Icon                 from "Styles/Images/Icon.png";
import Title                from "Styles/Images/Title.png";



// Styles
const AppSideBar = Styled(SideBar)`
    --bicon-size: 40px;
    --bicon-font: 18px;
`;

const AppTopBar = Styled(TopBar)`
    h2 img {
        width: 220px;
        max-width: calc(100vw - 12px * 2 - 8px * 3 - 32px * 2 - 36px);
    }
`;

const BarIconMobile = Styled(BarIcon)`
    @media (max-width: 500px) {
        display: none;
    }
`;



/**
 * The App
 * @returns {React.ReactElement}
 */
function App() {
    const { showChatSupport, chatSupportUnread } = Store.useState("global");
    const { isAnyAdmin, credential, currOrganization, edition } = Store.useState("auth");
    const { hasPremiumSupport, hasTickets } = Store.useState("permission");

    const { setShowChatSupport } = Store.useAction("global");


    // The References
    const targetRef = React.useRef(null);

    // The Current State
    const [ showMenu, setShowMenu ] = React.useState(false);


    // Do the Render
    const hasChat = !isAnyAdmin && hasPremiumSupport && hasTickets && credential.credentialID;

    return <Container withTopBar>
        <NotificationsBar />
        <AppTopBar
            variant="light"
            logo={Title}
            avatarUrl="PROFILE"
            avatarEmail={credential.email}
            avatarAvatar={credential.avatar}
            avatarEdition={edition}
            parentTitle="ORGANIZATIONS_SINGULAR"
            parentName={currOrganization}
            menuItems={CredentialMenu()}
        >
            <BarIconMobile
                passedRef={targetRef}
                variant="light"
                icon="ticket"
                message="GENERAL_HELP"
                onClick={() => setShowMenu(!showMenu)}
                withText
            />
            <BarIconMobile
                isHidden={!hasChat}
                variant="light"
                icon="conversation"
                message="GENERAL_CHAT"
                onClick={() => setShowChatSupport(!showChatSupport)}
                badge={chatSupportUnread}
                withText
            />
            <Menu
                open={showMenu}
                targetRef={targetRef}
                direction="bottom left"
                gap={8}
                onClose={() => setShowMenu(false)}
            >
                {HelpMenu()}
            </Menu>
        </AppTopBar>

        <AppSideBar
            variant="darker"
            logo={Icon}
            logoWidth={40}
            expandMobile
        >
            <AdminMenu />
            <PartnerMenu />
            <UserMenu />
        </AppSideBar>

        <Inside>
            <AdminRouter />
            <PartnerRouter />
            <UserRouter />
            <CredentialRouter />
            {hasChat && <ChatSupport />}
        </Inside>
    </Container>;
}

export default App;
