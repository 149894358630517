import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import CampaignGroup        from "./CampaignGroup";
import CampaignCondition    from "./CampaignCondition";

// Dashboard
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import InputField           from "Dashboard/Components/Form/InputField";
import Alert                from "Dashboard/Components/Form/Alert";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.div`
    .condition-line::before {
        content: "";
        position: absolute;
        left: var(--campaign-cond-left);
        top: -12px;
        bottom: var(--campaign-cond-size);
        width: 1px;
        background-color: var(--input-border-color);
    }

    .condition-square {
        position: relative;
    }
    .condition-square::after {
        content: "";
        position: absolute;
        left: var(--campaign-cond-left);
        top: 50%;
        width: var(--campaign-cond-size);
        height: var(--campaign-cond-size);
        border: 1px solid var(--input-border-color);
        border-radius: var(--campaign-cond-radius);
        background: white;
        transform: translate(-50%, -50%);
    }
`;

const AlertError = Styled(Alert)`
    margin-bottom: 12px;
`;

const Content = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    margin-left: var(--campaign-cond-width);
`;

const Buttons = Styled.div`
    display: flex;
    gap: 12px;

    :empty {
        display: none;
    }
`;



/**
 * The Campaign Conditions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignConditions(props) {
    const { data, getOptionError, getGlobalError, onChange } = props;

    const { elem, groupsDraft, conditionsDraft } = Store.useState("campaign");
    const { createGroup } = Store.useAction("campaign");


    // The Current State
    const [ isCreating, setCreating ] = React.useState(false);


    // Do the Render
    const conditions = conditionsDraft.filter((elem) => !elem.groupID);
    const showNone   = Boolean(!isCreating && !groupsDraft.length && !conditions.length);

    return <Container>
        <AlertError variant="error" message={getOptionError("conditionsForm")} />

        <InputField
            type="select"
            name="baseMatch"
            options="SELECT_CONDITION_MATCHES"
            value={data.baseMatch}
            error={getOptionError("baseMatch")}
            onChange={onChange}
            hideClear
            isSmall
        />

        <Content className="condition-line">
            <NoneAvailable
                isHidden={!showNone}
                message="CAMPAIGNS_CONDITIONS_NONE_AVAILABLE"
            />

            {groupsDraft.map((item) => <CampaignGroup
                key={item.groupID}
                item={item}
                getGlobalError={getGlobalError}
            />)}

            {conditions.map((item) => <CampaignCondition
                key={item.conditionID}
                item={item}
                setCreating={setCreating}
                getGlobalError={getGlobalError}
            />)}

            {isCreating && <CampaignCondition
                setCreating={setCreating}
                getGlobalError={getGlobalError}
            />}

            <Buttons className="condition-square">
                <Button
                    isHidden={isCreating}
                    variant="outlined"
                    message="CAMPAIGNS_CONDITIONS_ADD_GROUP_TITLE"
                    onClick={() => createGroup(elem.campaignID)}
                />
                <Button
                    isHidden={isCreating}
                    variant="outlined"
                    message="GENERAL_ADD_CONDITION"
                    onClick={() => setCreating(true)}
                />
            </Buttons>
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignConditions.propTypes = {
    data           : PropTypes.object.isRequired,
    getOptionError : PropTypes.func.isRequired,
    getGlobalError : PropTypes.func.isRequired,
    onChange       : PropTypes.func.isRequired,
};

export default CampaignConditions;
