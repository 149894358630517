import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";
import { HospitalityIO }    from "Utils/API";

// Components
import HospitalityHeader    from "./HospitalityHeader";
import HospitalityDetails   from "./HospitalityDetails";
import HospitalityDialogs   from "./HospitalityDialogs";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Hospitality List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityList(props) {
    const { type } = props;
    const { fetch, load, loadTab, loadFilter } = useList("hospitality", type);

    const { hospitalityID } = Navigate.useParams();
    const clientID          = Commons.useClientID();
    const navigate          = Commons.useGoto();

    const data = Store.useState("hospitality");
    const { loading, canEdit, list, total, hotels, statuses, filters, sort, elem } = data;

    const { openDetails } = Store.useAction("core");
    const { fetchElem, removeElem } = Store.useAction("hospitality");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Removes the Hospitality on exit
    React.useEffect(() => {
        return () => {
            removeElem();
        };
    }, []);

    // Handles the Hospitality change
    React.useEffect(() => {
        if (hospitalityID) {
            fetchElem(hospitalityID);
        }
    }, [ hospitalityID ]);

    // Handles the Hospitality charge
    React.useEffect(() => {
        if (elem.id) {
            openDetails();
        }
    }, [ elem.id ]);


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isTab) {
            endAction();
            loadTab(elemID);
        } else if (action.isView) {
            endAction();
            if (elemID !== hospitalityID) {
                navigate("HOSPITALITIES", elemID);
            } else {
                openDetails();
            }
        } else if (action.isExport) {
            setExporting(true);
            endAction();
            const source = HospitalityIO.export({ ...filters, clientID });
            await Utils.download(source, "hospitality.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };


    // Do the Render
    const withNavigation = type === "CLIENT";
    const showHotel      = hotels.length > 1;

    return <Main withNavigation={withNavigation}>
        <HospitalityHeader
            startAction={handleAction}
            isExporting={exporting}
        />

        <Content withDetails={!!elem.id}>
            <TabList selected={sort.filter} onAction={handleAction}>
                {statuses.map((elem) => <TabItem
                    key={elem.key}
                    value={elem.key}
                    message={elem.value}
                />)}
            </TabList>

            <Table
                fetch={load}
                sort={sort}
                none="HOSPITALITY_NONE_AVAILABLE"
                isLoading={loading}
                hasTabs
            >
                <TableHead>
                    <TableHeader field="contactName"   message="CONTACTS_SINGULAR"     isTitle />
                    <TableHeader field="hotelName"     message="HOTELS_SINGULAR"       isHidden={!showHotel} />
                    <TableHeader field="externalID"    message="GENERAL_ID"            maxWidth="160" />
                    <TableHeader field="roomNumber"    message="HOSPITALITY_ROOM"      maxWidth="120" />
                    <TableHeader field="arrivalTime"   message="HOSPITALITY_ARRIVAL"   maxWidth="120" />
                    <TableHeader field="departureTime" message="HOSPITALITY_DEPARTURE" maxWidth="120" />
                </TableHead>
                <TableBody>
                    {list.map((item) => <TableRow
                        key={item.hospitalityID}
                        elemID={item.hospitalityID}
                        isSelected={item.hospitalityID === elem.id}
                    >
                        <TableCell message={item.contactName}    />
                        <TableCell message={item.hotelName}      />
                        <TableCell message={item.externalID}     />
                        <TableCell message={item.roomNumber}     />
                        <TableCell message={item.checkedInDate}  />
                        <TableCell message={item.checkedOutDate} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"         message="HOSPITALITY_VIEW_TITLE"     />
                    <TableAction action="EDIT"         message="HOSPITALITY_EDIT_TITLE"     />
                    <TableAction action="CONTACT"      message="CONTACTS_EDIT_TITLE"        />
                    <TableAction action="CONVERSATION" message="CONVERSATIONS_CREATE_TITLE" />
                    <TableAction action="DELETE"       message="HOSPITALITY_DELETE_TITLE"   />
                </TableActionList>
            </Table>

            <HospitalityDetails startAction={startAction} />
        </Content>

        <HospitalityDialogs
            action={action}
            elemID={elemID}
            endAction={endAction}
            fetch={fetch}
            loadFilter={loadFilter}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default HospitalityList;
