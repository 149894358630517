import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import { Account, Contact } from "Utils/API";

// Components
import AccountEdit          from "./AccountEdit";
import ContactEdit          from "Components/App/Client/Contact/Dialogs/ContactEdit";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Account Assign Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AccountAssign(props) {
    const {
        openAssign, openUnassign, elemID, elemName,
        accountID, contactID, clientID, onClose, onSubmit,
    } = props;

    const { assignAccount, unassignAccount } = Store.useAction("account");


    // The Current State
    const [ openAccount, setOpenAccount ] = React.useState(false);
    const [ openContact, setOpenContact ] = React.useState(false);

    // The Initial Data
    const initialData = {
        accountID   : 0,
        accountName : "",
        contactID   : 0,
        contactName : "",
    };

    // Reset the Loader
    React.useEffect(() => {
        if (openAssign) {
            setElem({ ...initialData });
        }
    }, [ openAssign ]);

    // Handles the Edit
    const handleEdit = (data) => {
        if (accountID && !data.accountID) {
            data.accountID = accountID;
        }
        if (contactID && !data.contactID) {
            data.contactID = contactID;
        }
        return assignAccount({ ...data, clientID });
    };

    // Handles the Unassign Submit
    const handleUnassign = async () => {
        const result = await unassignAccount(elemID);
        onClose(!!result.success);
    };

    // Handles the Account Create
    const handleAccountCreate = (accountID, response) => {
        setOpenAccount(false);
        setData({
            accountID   : response.account.id,
            accountName : response.account.name,
        });
    };

    // Handles the Contact Create
    const handleContactCreate = (contactID, response) => {
        setOpenContact(false);
        setData({
            contactID   : response.contact.id,
            contactName : response.contact.fullName,
        });
    };


    // The Form State
    const {
        loading, data, errors, setElem, setData, handleChange, handleSubmit,
    } = useForm("accountAssign", initialData, handleEdit, onSubmit, false);



    // Do the Render
    return <>
        <EditDialog
            open={openAssign}
            icon="assign"
            title={accountID ? "ACCOUNTS_ASSIGN_CONTACT_TITLE" : "ACCOUNTS_ASSIGN_ACCOUNT_TITLE"}
            message={NLS.format(accountID ? "ACCOUNTS_ASSIGN_CONTACT_TEXT" : "ACCOUNTS_ASSIGN_ACCOUNT_TEXT", elemName)}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={!!accountID}
                type="suggest"
                name="accountName"
                label="ACCOUNTS_SINGULAR"
                suggestID="accountID"
                suggestFetch={Account.search}
                suggestParams={{ clientID }}
                value={data.accountName}
                error={errors.accountID}
                onChange={handleChange}
                button="GENERAL_CREATE"
                onClick={() => setOpenAccount(true)}
                isRequired
            />
            <InputField
                isHidden={!!contactID}
                type="suggest"
                name="contactName"
                label="CONTACTS_SINGULAR"
                suggestID="contactID"
                suggestFetch={Contact.search}
                suggestParams={{ clientID }}
                value={data.contactName}
                error={errors.contactID}
                onChange={handleChange}
                button="GENERAL_CREATE"
                onClick={() => setOpenContact(true)}
                isRequired
            />
        </EditDialog>

        <ConfirmDialog
            open={openUnassign}
            icon="delete"
            title="ACCOUNTS_UNASSIGN_CONTACT_TITLE"
            message="ACCOUNTS_UNASSIGN_CONTACT_TEXT"
            onSubmit={handleUnassign}
            onClose={onClose}
        />

        <AccountEdit
            open={openAccount}
            clientID={clientID}
            onSubmit={handleAccountCreate}
            onClose={() => setOpenAccount(false)}
        />
        <ContactEdit
            open={openContact}
            clientID={clientID}
            onSubmit={handleContactCreate}
            onClose={() => setOpenContact(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AccountAssign.propTypes = {
    openAssign   : PropTypes.bool,
    openUnassign : PropTypes.bool,
    onClose      : PropTypes.func.isRequired,
    onSubmit     : PropTypes.func.isRequired,
    elemID       : PropTypes.number,
    elemName     : PropTypes.string,
    accountID    : PropTypes.number,
    contactID    : PropTypes.number,
    clientID     : PropTypes.number,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
AccountAssign.defaultProps = {
    openAssign   : false,
    openUnassign : false,
};

export default AccountAssign;
