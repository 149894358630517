import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import PartnerEdit          from "Components/App/Admin/Partner/PartnerEdit";
import DashboardPartnerPage from "Components/App/Dashboard/DashboardPartnerPage";
import ClientList           from "Components/App/Admin/Client/ClientList";
import SubscriptionList     from "Components/App/Admin/Subscription/SubscriptionList";
import UserList             from "Components/App/Client/User/UserList";
import TicketList           from "Components/App/Support/Ticket/TicketList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Partner Page
 * @returns {React.ReactElement}
 */
function PartnerPage() {
    const { partnerID } = Navigate.useParams();

    const { closeMenu } = Store.useAction("core");

    const { elem } = Store.useState("partner");
    const { fetchElem } = Store.useAction("partner");

    // The Current State
    const [ action, setAction ] = Action.useAction();


    // Load the Data
    React.useEffect(() => {
        fetchElem(partnerID);
    }, [ partnerID ]);

    // Handles the Action
    const handleAction = (action) => {
        if (action.isCE) {
            closeMenu();
            setAction(action);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(partnerID);
        setAction();
    };


    // Do the Render
    return <>
        <SecondaryNav
            message={elem.name}
            fallback="PARTNERS_SINGULAR"
            href={Navigate.useFrom()}
            onAction={handleAction}
            canEdit
        >
            <NavigationList>
                <NavigationItem
                    message="DASHBOARD_NAME"
                    url="DASHBOARD"
                    icon="dashboard"
                />
                <NavigationItem
                    message="CLIENTS_NAME"
                    url="CLIENTS"
                    icon="client"
                />
                <NavigationItem
                    message="USERS_NAME"
                    url="USERS"
                    icon="user"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="SUBSCRIPTIONS_NAME"
                    url="SUBSCRIPTIONS"
                    icon="subscription"
                />
                <NavigationItem
                    message="TICKETS_NAME"
                    url="TICKETS"
                    icon="ticket"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="PARTNER">
            <UserRoute
                url="DASHBOARD"
                component={DashboardPartnerPage}
            />
            <UserRoute
                url="CLIENTS"
                component={ClientList}
            />
            <UserRoute
                url="USERS"
                component={UserList}
            />
            <UserRoute
                url="USER"
                component={UserList}
            />
            <UserRoute
                url="SUBSCRIPTIONS"
                component={SubscriptionList}
            />
            <UserRoute
                url="TICKETS"
                component={TicketList}
            />
        </Router>

        <PartnerEdit
            open={action.isEdit}
            elemID={partnerID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
    </>;
}

export default PartnerPage;
