import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Content = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 4px 4px 8px;
    font-size: 13px;
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
    border: 1px solid var(--lightest-color);
`;

const Text = Styled.div`
    width: calc(100% - 16px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
const Close = Styled(IconLink)`
    --link-size: 20px;
    font-size: 12px;
    border-radius: var(--border-radius-small);
`;



/**
 * The Reply Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyContent(props) {
    const { isHidden, title, message, onClose } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Content>
        <Text>
            {!!title && <><b>{NLS.get(title)}</b><br /></>}
            {message}
        </Text>
        <Close
            icon="close"
            onClick={() => onClose()}
        />
    </Content>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyContent.propTypes = {
    isHidden : PropTypes.bool,
    title    : PropTypes.string,
    message  : PropTypes.string,
    onClose  : PropTypes.func.isRequired,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
ReplyContent.defaultProps = {
    isHidden : false,
    title    : "",
    message  : "",
};

export default ReplyContent;
